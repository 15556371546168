import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';
// eslint-disable-next-line
{/* <link rel="stylesheet" type="text/css" href="css/landscape.css" media="screen and (orientation: landscape)">
<link rel="stylesheet" type="text/css" href="css/portrait.css" media="screen and (orientation: portrait)"> */}


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
 document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
