import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const About = (props) => {
  const [device, setDevice] = useState(props.props)
  //eslint-disable-next-line
  const [cookies, setCookies] = useState(props.props)
  const navigate = useNavigate()

  useEffect(() => {
    setDevice(props.device)
  }, [props])

  useEffect(() => {
    setCookies(props.cookie)
    if (document.cookie === "") {
      navigate("/home");
      window.location.reload();
    } 
  }, [navigate, props])

  return (
    <div className="page-container" style={ device === "mobile" ? {"fontSize":"12pt"} : {} || device === "tablet" ? {"fontSize":"14pt"} : {} }>
      <div className="page-container-2">
      <div className="page-heading" style={ device === "mobile" ? {"fontSize":"20px"} : {} || device === "tablet" ? {"fontSize":"22pt"} : {} }>
          Unternehmen
        </div>
        <div className='purchasing-content-block' style={ device === "mobile" || device === "tablet" ? {textAlign:"left"} : {textAlign:"justify"}}>
          Das Autohaus Kauschinger wurde vor knapp 10 Jahren von Christoph Kauschinger gegründet. Zuvor war Christoph als Lackierermeister beim AVP Autoland tätig. 
        </div>
        <div className='purchasing-content-block' style={ device === "mobile" || device === "tablet" ? {textAlign:"left"} : {textAlign:"justify"}}>
          Das Autohaus Kauschinger wurde mit dem Ziel gegründet Ihnen eine ehrliche Beratung und faire Preise im Gebrauchtwagenmarkt zu bieten. Der Gebrauchtwagenmarkt ist leider nicht immer vollständig transparent, vor allem wenn Sie kein Experte sind. Aus eigener Erfahrung wissen wir, dass nicht alle Händler im Interesse Ihrer Kunden handeln. Genau das sollte bei uns anders sein. Insbesondere die ehrliche Beratung wurde uns bereits in vielen Bewertungen bestätigt.
        </div>
        <div className='purchasing-content-block' style={ device === "mobile" || device === "tablet" ? {textAlign:"left"} : {textAlign:"justify"}}>
          Seit der Gründung im September 2016 hat sich das Autohaus Kauschinger zur ersten Wahl für hochwertige, junge Gebrauchtfahrzeuge in Deggendorf entwickelt. Da wir als unabhängiges Autohaus agieren, sind wir nicht an die Preisevorgaben und Abnahmequoten der Hersteller gebunden.
        </div>
        <div className='purchasing-content-block' style={ device === "mobile" || device === "tablet" ? {textAlign:"left", fontWeight:"bold"} : {textAlign:"justify", fontWeight:"bold"}}>
          Das Autohaus Kauschinger ist als Familienbetrieb tätig:
          <ul className="vehicle-history">
            <li className="vehicle-history">Christoph Kauschinger: Inhaber und Geschäftsführer</li>
            <li className="vehicle-history">Anita Kauschinger: Bürotätigkeiten und Fahrzeugaufbereitung</li>
            <li className="vehicle-history">Friedrich Kauschinger: Logistik</li>
            <li className="vehicle-history">Martin Kauschinger: IT</li>
          </ul>
        </div>
        <div className='image-container' style={{marginBottom:"20px"}}> 
              <img src="/images/profile.jpg" style={ device === "mobile"  || device === "tablet" ? {width:"80%"} : {width:"55%"}} alt='unternehmen'/>
            </div>  
      </div>
    </div>
  );
};


  
export default About;