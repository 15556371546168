import React from 'react';
/* import { ImCross } from "react-icons/im";
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { IconContext } from 'react-icons/lib'; */



function getFeatureName(value) {
    var myFeatureName = value.value
    var mySliceIndex = myFeatureName.lastIndexOf(" ")
    myFeatureName = myFeatureName.slice(0,mySliceIndex)
    return myFeatureName
}

function getFeatureValue(value) {
    var myFeatureValue = value.value
    var mySliceIndex = myFeatureValue.lastIndexOf(" ")
    myFeatureValue = myFeatureValue.slice(mySliceIndex+1,)
    myFeatureValue = parseInt(myFeatureValue.slice(0,myFeatureValue.search("%")))
    return myFeatureValue
} 

const OverViewFeature = ({value, loading, wideFooter}) => {
    if(loading) {
        return <div className="Loading">Loading...</div>;
    }

    // console.log(wideFooter)
    let featureName = getFeatureName(value)
    let featureValue = getFeatureValue(value)
/*     featureValue = 50 */
  return (
    <div className='reviewline-overview-feature'>
        <span className="ratingDimension-overview-feature">{featureName}:</span>
        <div className="progressLine">
            <div className="progressBar" style={wideFooter === true ? {}:{width:"75%"}}>
                    <div className="statusBar" style={{width: featureValue + '%'}}> </div> 
                </div>
            <div className="statusBarValue">{featureValue}%</div>
        </div>
    </div>
  ) 
}

export default OverViewFeature;
