import React from 'react';

function returnStarNumbers(myVal) {
  let myFullStars = Math.trunc(myVal);
  let myEmptyStars = 0;
  let myMixedStars = 0;
  let myShadingOfMixedStars = 0;
  if (Number.isInteger(myVal)) { 
    myEmptyStars = 5 - myFullStars;
    myMixedStars = 0;
    myShadingOfMixedStars = 0;
    return {
      fullStars: myFullStars,
      emptyStars: myEmptyStars,
      mixedStars: myMixedStars,
      ShadingOfMixedStars: myShadingOfMixedStars
    };
  } else {
    myEmptyStars = 5 - myFullStars - 1;
    myMixedStars = 1
    myShadingOfMixedStars = myVal - myFullStars;
    return {
      fullStars: myFullStars,
      emptyStars: myEmptyStars,
      mixedStars: myMixedStars,
      ShadingOfMixedStars: myShadingOfMixedStars
    };
  }
}


const OverViewStarRatingSingle = ({value, loading, twoColumnLayout, device}) => {
  // console.log(device)

    if(loading) {
        return <div className="Loading">Loading...</div>;
    } 

    value = value.value
    var myRatingDimension = value.slice(value.search(" "),)
    var myRatingValue = parseFloat(value.slice(0,value.search(" ")).replace(/,/g, "."))
    let myRatingValueString = myRatingValue.toFixed(1).toString().replace(".", ",")
    let {fullStars, emptyStars, mixedStars, ShadingOfMixedStars} = returnStarNumbers(myRatingValue);

    if (myRatingDimension === 'd') {
        return "";
    } else if (!twoColumnLayout) {
      return (
        <div className="reviewline-overview-single"> 
          {
            (() => {
                if (device === "mobile") {
                  return (
                    <>
                      <span className="ratingDimension-overview-single" style={ twoColumnLayout ? {width:"160px"} : {width:"10rem"}}>
                        { myRatingDimension === " Gesamt" ? <b>{myRatingDimension}: {myRatingValueString}</b>: <>{myRatingDimension}: {myRatingValueString}</>}
                      </span>                    
                    </>
                  )
                } else if (device === "tablet") {
                  return (
                    <>
                      <span className="ratingDimension-overview-single" style={ twoColumnLayout ? {width:"160px"} : {width:"15rem"}}>
                        { myRatingDimension === " Gesamt" ? <b>{myRatingDimension}: {myRatingValueString}</b>: <>{myRatingDimension}: {myRatingValueString}</>}
                      </span>                            
                    </>
                  )
                }
            })()
          }
          <span className='ratingDimensionStars-overview-single' style={ twoColumnLayout ? {width:"160px"} : {width:"10rem"}}>
            { 
              [...Array(fullStars)].map((currentElement, index) => 
                <span className="stars-overview-single" key={index}>
                  <svg className="star-overview-single" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 52 40">
                    <defs>
                        <linearGradient id="grad7">
                            <stop offset="100%" stopColor="#f2b713"/>
                            <stop offset="0%" stopColor="#7f7a7a"/>
                        </linearGradient>
                    </defs>
                    <path fill="url(#grad7)" d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118 l11.547-1.2L16.026,0.6L20.388,10.918z"/>
                  </svg>
                </span>
              )
            }
            {
              (() => {
                if(mixedStars > 0) {
                  return (
                    <span className="stars-overview-single" key="mixed">
                    <svg className="star-overview-single" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 52 40">
                      <defs>
                          <linearGradient id="grad8">
                            <stop offset={ShadingOfMixedStars} stopColor="#f2b713"/>
                            <stop offset="0%" stopColor="#7f7a7a"/>
                          </linearGradient>
                      </defs>
                      <path fill="url(#grad8)" d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118 l11.547-1.2L16.026,0.6L20.388,10.918z"/>
                    </svg>
                  </span>
                  )
                } 
              })()  
            }
            {
              (() => {
                if(emptyStars > 0) {
                  return (
                    [...Array(emptyStars)].map((currentElement, index) => 
                    <span className="stars-overview-single" key={index}>
                      <svg className="star-overview-single" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 52 40">
                        <defs>
                            <linearGradient id="grad9">
                                <stop offset="0%" stopColor="#f2b713"/>
                                <stop offset="0%" stopColor="#7f7a7a"/>
                            </linearGradient>
                        </defs>
                        <path fill="url(#grad9)" d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118 l11.547-1.2L16.026,0.6L20.388,10.918z"/>
                      </svg>
                    </span>
                  )
                  )
                } 
              })()  
            }
          </span>
        </div>
      )
    } 
    else {
      return (
        <div className="reviewline-overview-single"> 
          <span className="ratingDimension-overview-single">{myRatingDimension}:</span>
          <span className='ratingDimensionStars-overview-single'>
            { 
              [...Array(fullStars)].map((currentElement, index) => 
                <span className="stars-overview-single" key={index}>
                  <svg className="star-overview-single" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 52 40">
                    <defs>
                        <linearGradient id="grad7">
                            <stop offset="100%" stopColor="#f2b713"/>
                            <stop offset="0%" stopColor="#7f7a7a"/>
                        </linearGradient>
                    </defs>
                    <path fill="url(#grad7)" d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118 l11.547-1.2L16.026,0.6L20.388,10.918z"/>
                  </svg>
                </span>
              )
            }
            {
              (() => {
                if(mixedStars > 0) {
                  return (
                    <span className="stars-overview-single" key="mixed">
                    <svg className="star-overview-single" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 52 40">
                      <defs>
                          <linearGradient id="grad8">
                            <stop offset={ShadingOfMixedStars} stopColor="#f2b713"/>
                            <stop offset="0%" stopColor="#7f7a7a"/>
                          </linearGradient>
                      </defs>
                      <path fill="url(#grad8)" d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118 l11.547-1.2L16.026,0.6L20.388,10.918z"/>
                    </svg>
                  </span>
                  )
                } 
              })()  
            }
            {
              (() => {
                if(emptyStars > 0) {
                  return (
                    [...Array(emptyStars)].map((currentElement, index) => 
                    <span className="stars-overview-single" key={index}>
                      <svg className="star-overview-single" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 52 40">
                        <defs>
                            <linearGradient id="grad9">
                                <stop offset="0%" stopColor="#f2b713"/>
                                <stop offset="0%" stopColor="#7f7a7a"/>
                            </linearGradient>
                        </defs>
                        <path fill="url(#grad9)" d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118 l11.547-1.2L16.026,0.6L20.388,10.918z"/>
                      </svg>
                    </span>
                  )
                  )
                } 
              })()  
            }
          </span>
          <span className='ratingDimensionNumber-overview-single'>{myRatingValueString}</span>
        </div>
      )
    }

}
  
export default OverViewStarRatingSingle




