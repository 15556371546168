import React, { useEffect, useState } from 'react';
import {  NavLink, useNavigate } from "react-router-dom";
import SidebarMobile from './SidebarMobile';

const urlBasePath= process.env.REACT_APP_URL_BASE_PATH

const Navbar = (props) => {
  const [isClicked, setIsClicked] = useState(false);
  const [pointerEvents, setPointerEvents] = useState("none")
  const navigate = useNavigate()

  const clearStates = (e) => {
    if (e.target.href !== window.location.href){
      document.querySelector("[href='/fahrzeuge']").className = ``;
      document.querySelector("[href='/bewertungen']").className = ``;
    }

    if ( document.cookie === "" && e.target.href === urlBasePath + "/home") {
      navigate("/home");
      window.location.reload();
    }
  }

  const passIsClicked = () => {
    setIsClicked(true);
  }

  useEffect(() => {
    if (window.location.href===urlBasePath + "/datenschutz" || window.location.href===urlBasePath + "/cookies" || window.location.href===urlBasePath + "/impressum") {
      if (props.device === "mobile" || props.device === "tablet") {
        const element = document.querySelector("div.site-mobile")
        if (element.style.opacity !== "1") {
          setPointerEvents(false)
        } else {
          setPointerEvents(true)
        }
      } else if (props.device === "desktop") {
        const element = document.querySelector("div.site-desk")
        if (element.style.opacity !== "1") {
          setPointerEvents(false)
        } else {
          setPointerEvents(true)
        }
      }
    } else if (document.cookie !== "") {
      setPointerEvents(true)
    } else {
      setPointerEvents(false)
    }
  }, [props])



  // useEffect(() => {
  //   if (props.device === "mobile" || props.device === "tablet") {
  //     const element = document.querySelector("div.site-mobile")
  //     if (element.style.opacity !== 1) {
  //       setPointerEvents(false)
  //     } else {
  //       setPointerEvents(true)
  //     }
  //   } else if (props.device === "desktop") {
  //     const element = document.querySelector("div.site-desk")
  //     if (element.style.opacity !== 1) {
  //       setPointerEvents(false)
  //     } else {
  //       setPointerEvents(true)
  //     }
  //   }
  // }, [props])





  if(props.device === "mobile" || props.device === "tablet") {
    return (
      <div className="navbar_mobile" style={pointerEvents ? {pointerEvents: "auto"} : {pointerEvents: "none"}}>
        <div className="navbar_mobile-stretch">
          <div className={props.device === "mobile" ? "navbar_mobile-element-empty-box" : "navbar_tablet-element-empty-box"}>
          </div>
          <div className={props.device === "mobile" ? "navbar_mobile-element-text" : "navbar_mobile-tablet-text"} onClick={passIsClicked}>
            Navigation
          </div>
          <div className={props.device === "mobile" ? "navbar_mobile-element-bm-menu" : "navbar_tablet-element-bm-menu"}>
            <SidebarMobile stateChanger={setIsClicked} props={isClicked} device={props.device} cookie={props.cookie}/>
          </div>
        </div>
      </div>
    )
  } else {
  return (
    <div className="navbar" style={pointerEvents ? {pointerEvents: "auto"} : {pointerEvents: "none"}}>
      <div className="navbar_stretch">
        <NavLink to="/home" onClick={clearStates} >Home</NavLink> 
        <NavLink to="/fahrzeuge" onClick={clearStates} >Fahrzeuge</NavLink> 
        <NavLink to="/bewertungen" onClick={clearStates} >Bewertungen</NavLink> 
        <NavLink to="/about" onClick={clearStates} >Unternehmen</NavLink> 
        <NavLink to="/ankauf" onClick={clearStates} >Ankauf</NavLink> 
        <NavLink to="/kontakt" onClick={clearStates} >Kontakt</NavLink> 
      </div>
    </div>
  )
}
}

export default Navbar;

