import React, { useEffect, useState } from 'react';
  
const VehiclelistHeaderline = (vehicles) => {

    const [device, setDevice] = useState(vehicles.device)
    useEffect(() => {
        setDevice(vehicles.device)
      }, [vehicles])

    return (
        <div className="list-group-vehicle-item-headerline">
            {
            (() => {
                if (device==="mobile" || device==="tablet") {
                    if (!vehicles.props.isRecommendedVehicle && !vehicles.props.isNewVehicle) {
                        return (<div className="list-group-vehicle-item-headerline-full-mobile" style={ device === "tablet" ? { padding:"10px"} : {}}>{vehicles.props.vehicleName}</div>)
                    } else if (vehicles.props.isRecommendedVehicle && !vehicles.props.isNewVehicle) {
                        return (
                            <>
                                <div className="list-group-vehicle-item-headerline-left-ragged-mobile" style={ device === "tablet" ? {padding:"10px", width:"85%"} : {width:"85%"}}>{vehicles.props.vehicleName}</div>
                                <div className="list-group-vehicle-item-headerline-right-ragged-mobile" style={{width:"15%"}}> 
                                    <div className="list-group-vehicle-item-headerline-stretch-mobile">
                                        <div className="list-group-vehicle-item-headerline-element-recommend-mobile" style={{width:"100%"}}> TIPP </div>
                                    </div>
                                </div>
                            </>
                            )
                    } else if (!vehicles.props.isRecommendedVehicle && vehicles.props.isNewVehicle) {
                        return (
                            <>
                                <div className="list-group-vehicle-item-headerline-left-ragged-mobile" style={ device === "tablet" ? {padding:"10px", width:"85%"} : {width:"85%"}}>{vehicles.props.vehicleName}</div>
                                <div className="list-group-vehicle-item-headerline-right-ragged-mobile" style={{width:"15%"}}> 
                                    <div className="list-group-vehicle-item-headerline-stretch-mobile">
                                        <div className="list-group-vehicle-item-headerline-element-new-mobile" style={device === "tablet" ? {width:"100%"} : {width:"100%"}}> NEU</div>
                                        {/* <div className="list-group-vehicle-item-headerline-element-new-mobile" style={{width:"100%"}}> NEU</div> */}
                                    </div>
                                </div>
                            </>
                        )
                    } else if (vehicles.props.isRecommendedVehicle && vehicles.props.isNewVehicle) {
                        return (

                            <>
                                <div className="list-group-vehicle-item-headerline-left-ragged-mobile" style={ device === "tablet" ? {padding:"10px", width:"70%"} : {width:"70%"}}>{vehicles.props.vehicleName}</div>
                                <div className="list-group-vehicle-item-headerline-right-ragged-mobile" style={{width:"30%"}}> 
                                    <div className="list-group-vehicle-item-headerline-stretch-mobile">
                                        <div className="list-group-vehicle-item-headerline-element-recommend-mobile"> TIPP</div>
                                        <div className="list-group-vehicle-item-headerline-element-new-mobile"> NEU</div>
                                    </div>
                                </div>
                             </>

                            // <>
                            //     <div className="list-group-vehicle-item-headerline-full-mobile">{vehicles.props.vehicleName}</div>
                            //     <div>recommended and new </div>
                            // </>
                        )
                    }           
                } else if (device==="desktop"){
                    if (!vehicles.props.isRecommendedVehicle && !vehicles.props.isNewVehicle) {
                        return (<div className="list-group-vehicle-item-headerline-full-desk">{vehicles.props.vehicleName}</div>)
                    } else if (vehicles.props.isRecommendedVehicle && !vehicles.props.isNewVehicle) {
                    return (
                        <>
                            <div className="list-group-vehicle-item-headerline-left-ragged-desk" style={{width:"85%"}}>{vehicles.props.vehicleName}</div>
                            <div className="list-group-vehicle-item-headerline-right-ragged-desk" style={{width:"15%"}}> 
                                <div className="list-group-vehicle-item-headerline-stretch">
                                    <div className="list-group-vehicle-item-headerline-element-recommend"> EMPFEHLUNG </div>
                                </div>
                            </div>
                        </>
                    )
                    } else if (!vehicles.props.isRecommendedVehicle && vehicles.props.isNewVehicle) {
                    return (
                        <>
                            <div className="list-group-vehicle-item-headerline-left-ragged-desk" style={{width:"85%"}}>{vehicles.props.vehicleName}</div>
                            <div className="list-group-vehicle-item-headerline-right-ragged-desk" style={{width:"15%"}}> 
                                <div className="list-group-vehicle-item-headerline-stretch">
                                    <div className="list-group-vehicle-item-headerline-element-new"> NEU</div>
                                </div>
                            </div>
                        </>
                    )
                    } else if (vehicles.props.isRecommendedVehicle && vehicles.props.isNewVehicle) {
                    return (
                        <>
                            <div className="list-group-vehicle-item-headerline-left-ragged-desk" style={{width:"70%"}}>{vehicles.props.vehicleName}</div>
                            <div className="list-group-vehicle-item-headerline-right-ragged-desk" style={{width:"30%"}}> 
                                <div className="list-group-vehicle-item-headerline-stretch">
                                    <div className="list-group-vehicle-item-headerline-element-recommend"> EMPFEHLUNG</div>
                                    <div className="list-group-vehicle-item-headerline-element-new"> NEU</div>
                                </div>
                            </div>
                        </>
                    )
                    } 
                }
            })()
            }
        </div> 
    );
};

export default VehiclelistHeaderline;