import React from 'react';
import StarRating from './StarRating';
import StarRatingMobile from './StarRatingMobile';
import StarRatingSummary from './StarRatingSummary';
import StarRatingSummaryMobile from './StarRatingSummaryMobile';
import { IoThumbsUp, IoThumbsDown } from 'react-icons/io5';
import FeatureMobile from './FeatureMobile';



const ReviewList = ({ reviews, loading, startIndex, endIndex, totalReviews, twoColumnLayout}) => {
    // console.log(twoColumnLayout)
    var currentReviews = reviews
    const myOptions = {
        dateStyle: "full"
    }

    if(loading) {
        return <div className="Loading">Loading...</div>;
    }

    else if (!twoColumnLayout) {
        return (
            <>
            <div className="vehicle-listings"> Bewertungen {startIndex} bis {endIndex} von insgesamt {totalReviews} Bewertungen</div>
            <ul className="list-group-reviews-mobile"  id="reviews">
                {currentReviews?.map(currentReviews => (
                    <li key={currentReviews._id} className="list-group-reviews-item" style={{marginBottom:"25px"}}>
                        <div className="ratingLine-mobile" style={{backgroundColor:"#4d4949",color:"white", marginBlock:"8px"}}><div className='leftAligned'>Anonymisierter Nutzer am {new Date((currentReviews.reviewDate)).toLocaleDateString('de-DE', myOptions)}:</div><div className='rightAligned'>ID: {currentReviews.ID}</div></div>
                        <div className="ratingStarLineComments-mobile"><strong className='Overview'>Gesamt: </strong>
                            <StarRatingSummaryMobile value={{ value: currentReviews.reviewRating }}/>
                        </div>
                        <div className="ratingStarLineComments-mobile">
                            <strong className='Comments' style={{width:"24px"}}><IoThumbsUp></IoThumbsUp> </strong>
                            <span className="ratingStarLineCommentsContent">
                            {
                                (() => {
                                    var myVar = currentReviews.reviewerCommentsPositive.trim()
                                    if (myVar.includes("☺️")) {
                                        myVar = myVar.replace("☺️", "🙂")
                                    } 
                                    if ((myVar.charAt(myVar.length-1) !== ".") && (myVar.charAt(myVar.length-1) !== "!")) {
                                        return " " + myVar + "."
                                    } else {
                                        return " " + myVar
                                    }
                                })()
                            }
                            </span>
                        </div>
                        <div className="ratingStarLineComments-mobile">
                            <strong className='Comments' style={{width:"24px"}}><IoThumbsDown></IoThumbsDown></strong>
                            <span className="ratingStarLineCommentsContent">
                            {
                                (() => {
                                    if (currentReviews.reviewerCommentsNegative === '0') {
                                        return " Nichts."
                                    } else {
                                        var myVar = currentReviews.reviewerCommentsNegative.trim()
                                        if ((myVar.charAt(myVar.length-1) !== ".") && (myVar.charAt(myVar.length-1) !== "!")) {
                                            return " " + myVar + "."
                                        } else {
                                            return " " + myVar
                                        }
                                    }
                                })()  
                            }
                            </span>
                        </div>
                        <div className="ratingStarLineComments-mobile" style={{marginBottom:"8px"}}><strong className='Comments'>Details: </strong></div>
                            <StarRatingMobile value={{ value: currentReviews.ratingDimension1}}/>
                            <StarRatingMobile value={{ value: currentReviews.ratingDimension2}}/>
                            <StarRatingMobile value={{ value: currentReviews.ratingDimension3}}/>
                        <div style={{height:"24px"}}/>
                            <FeatureMobile value={{ feature: currentReviews.feature1 }}/>
                            <FeatureMobile value={{ feature: currentReviews.feature2 }}/>
                            <FeatureMobile value={{ feature: currentReviews.feature3 }}/>
                        <div className="paddingLine" style={{height:"6px"}}></div>   
                    </li>
                ))}
            </ul>
        </>
        )
    } else { 
        return (
            <>
                <div className="vehicle-listings"> Bewertungen {startIndex} bis {endIndex} von insgesamt {totalReviews} Bewertungen</div>
                <ul className="list-group-reviews-desk"  id="reviews">
                    {currentReviews?.map(currentReviews => (
                        <li key={currentReviews._id} className="list-group-reviews-item">
                            <div className="ratingLine" style={{backgroundColor:"#4d4949",color:"white"}}><span className='leftAligned'>Anonymisierter Nutzer am {new Date((currentReviews.reviewDate)).toLocaleDateString('de-DE', myOptions)}:</span><span className='rightAligned'>Review ID: {currentReviews.ID}</span></div>
                            {/* <div className="ratingLine" style={{backgroundColor:"#4d4949",color:"white"}}><span className='leftAligned'>{currentReviews.reviewer} am {new Date((currentReviews.reviewDate)).toLocaleDateString('de-DE', myOptions)}:</span><span className='rightAligned'>Review ID: {currentReviews.ID}</span></div> */}
                            <div className="paddingLine"></div>
                            <div className="ratingStarLineComments"><strong className='Overview'>Gesamt: </strong>
                                <StarRatingSummary value={{ value: currentReviews.reviewRating }}/>
                            </div>
                            <div className="paddingLine"/>   
                            <div className="ratingStarLineComments">
                                <strong className='Comments'><IoThumbsUp></IoThumbsUp> </strong>
                                <span className="ratingStarLineCommentsContent">
                                {
                                    (() => {
                                        var myVar = currentReviews.reviewerCommentsPositive.trim()
                                        if (myVar.includes("☺️")) {
                                            myVar = myVar.replace("☺️", "🙂")
                                        } 
                                        if ((myVar.charAt(myVar.length-1) !== ".") && (myVar.charAt(myVar.length-1) !== "!")) {
                                            return " " + myVar + "."
                                        } else {
                                            return " " + myVar
                                        }
                                    })()
                                }
                                </span>
                            </div>
                            <div className="paddingLine"></div>
                            <div className="ratingStarLineComments">
                                <strong className='Comments'><IoThumbsDown></IoThumbsDown></strong>
                                <span className="ratingStarLineCommentsContent">
                                {
                                    (() => {
                                        if (currentReviews.reviewerCommentsNegative === '0') {
                                            return " Nichts."
                                        } else {
                                            var myVar = currentReviews.reviewerCommentsNegative.trim()
                                            if ((myVar.charAt(myVar.length-1) !== ".") && (myVar.charAt(myVar.length-1) !== "!")) {
                                                return " " + myVar + "."
                                            } else {
                                                return " " + myVar
                                            }
                                        }
                                    })()  
                                }
                                </span>
                            </div>
                            <div className="paddingLine"></div>
                            <div className="ratingStarLineComments"><strong className='Comments'>Details: </strong></div>
                                <StarRating value={{ value: currentReviews.ratingDimension1, feature: currentReviews.feature1}}/>
                                <StarRating value={{ value: currentReviews.ratingDimension2, feature: currentReviews.feature2}}/>
                                <StarRating value={{ value: currentReviews.ratingDimension3, feature: currentReviews.feature3}}/>
                            <div className="paddingLine"></div>   
                        </li>
                    ))}
                </ul>
            </>
        )
    }
}

export default ReviewList
