import React from 'react';
import { ImCross } from "react-icons/im";
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { IconContext } from 'react-icons/lib';

function getExists(value) {
  if (value.includes("NoFeature")) {
    return false
  } else {
  return true
  }
}

function getFeatureName(value) {
  var myFeatureName = value
  myFeatureName = myFeatureName.slice(0,myFeatureName.indexOf(":")+1)
  return myFeatureName
}

function getFeatureValue(value) {
  var myFeatureValue = value
  myFeatureValue = myFeatureValue.slice(myFeatureValue.indexOf(":"),)
  myFeatureValue = myFeatureValue.slice(myFeatureValue.indexOf(" ") + 1,)
  if (myFeatureValue === 'True') {
    return true;
  } else {
    return false;
  }
}

const Feature = ({value}) => {
  let featureExists = getExists(value)
  let featureName = getFeatureName(value)
  let featureValue = getFeatureValue(value)
/*   console.log(featureName)
  console.log(featureValue)
  console.log(typeof(featureValue)) */
  return (
    <span className='features'> 
      {
        (() => {
          if(featureExists === true) {
            return (
              <>
                <span className='featureDimension'>{featureName} </span>
              </>
            )
            } 
          })()  
      }
      {
        (() => {
          if(featureExists === true && featureValue === true) {
            return (
              <span className='featureIcon'>{featureValue} 
                <IconContext.Provider value={{ className:'iconsTrue'}}>
                  <IoMdCheckmarkCircleOutline></IoMdCheckmarkCircleOutline>
                </IconContext.Provider>
              </span>
            )
            } 
          })()  
      }
      {
        (() => {
          if(featureExists === true && featureValue === false) {
            return (
              <span className='featureIcon'>{featureValue} 
                <IconContext.Provider value={{ className:'iconsFalse'}}>
                  <ImCross></ImCross>
                </IconContext.Provider>
              </span>
            )
            } 
          })()  
      }
    </span>
  ) 
}

export default Feature;
