import React from 'react';

function returnStarNumbers(myVal) {
  let myFullStars = Math.trunc(myVal);
  let myEmptyStars = 0;
  let myMixedStars = 0;
  let myShadingOfMixedStars = 0;
  if (Number.isInteger(myVal)) { 
    myEmptyStars = 5 - myFullStars;
    myMixedStars = 0;
    myShadingOfMixedStars = 0;
    return {
      fullStars: myFullStars,
      emptyStars: myEmptyStars,
      mixedStars: myMixedStars,
      ShadingOfMixedStars: myShadingOfMixedStars
    };
  } else {
    myEmptyStars = 5 - myFullStars - 1;
    myMixedStars = 1
    myShadingOfMixedStars = myVal - myFullStars;
    return {
      fullStars: myFullStars,
      emptyStars: myEmptyStars,
      mixedStars: myMixedStars,
      ShadingOfMixedStars: myShadingOfMixedStars
    };
  }
}


const StarRating = ({value}) => {
  value = value.value
  let myRatingDimension = value.slice(0,value.indexOf(" "));
  let myRatingValue = parseInt(value.slice(value.indexOf(" ")+1,))
  let {fullStars, emptyStars, mixedStars, ShadingOfMixedStars} = returnStarNumbers(myRatingValue);
  let myRatingValueString = myRatingValue.toFixed(1).toString().replace(".", ",")


  if (myRatingDimension === 'non') {
    return "";
  } else {
    return (
      <>
        <div className="ratingLine-mobile-features"> 
          {/* <span className="leftSide"> */}
          <span className="ratingDimension-mobile">{myRatingDimension}:</span>
          <span className='ratingDimensionStars'>
            { 
              [...Array(fullStars)].map((currentElement, index) => 
                <span className="stars" key={index}>
                  <svg className="star" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 52 40">
                    <defs>
                        <linearGradient id="grad">
                            <stop offset="100%" stopColor="#f2b713"/>
                            <stop offset="0%" stopColor="#7f7a7a"/>
                        </linearGradient>
                    </defs>
                    <path fill="url(#grad)" d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118 l11.547-1.2L16.026,0.6L20.388,10.918z"/>
                  </svg>
                </span>
              )
            }
            {
              (() => {
                if(mixedStars > 0) {
                        return (
                          <span className="stars" key="mixed">
                          <svg className="star" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 52 40">
                            <defs>
                                <linearGradient id="grad2">
                                    <stop offset={ShadingOfMixedStars} stopColor="#f2b713"/>
                                    <stop offset="0%" stopColor="#7f7a7a"/>
                                </linearGradient>
                            </defs>
                            <path fill="url(#grad2)" d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118 l11.547-1.2L16.026,0.6L20.388,10.918z"/>
                          </svg>
                        </span>
                        )
                    } 
              })()  
            }
            {
              (() => {
                if(emptyStars > 0) {
                  return (
                    [...Array(emptyStars)].map((currentElement, index) => 
                    <span className="stars" key={index}>
                      <svg className="star" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 52 40">
                        <defs>
                            <linearGradient id="grad3">
                                <stop offset="0%" stopColor="#f2b713"/>
                                <stop offset="0%" stopColor="#7f7a7a"/>
                            </linearGradient>
                        </defs>
                        <path fill="url(#grad3)" d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118 l11.547-1.2L16.026,0.6L20.388,10.918z"/>
                      </svg>
                    </span>
                  )
                  )
                } 
              })()  
            }
          </span>
          <span className='ratingDimensionNumber'>{myRatingValueString}</span>
        </div>
      </>
    )
  }
}


export default StarRating;
