import React, { Component } from 'react';


class Reviews extends Component {
  render() {
    return (
      <div className="footer-review"/> 
    );}
  // }
}
 
export default Reviews