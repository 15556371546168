import React, { useEffect, useState } from 'react';
import {  useNavigate } from "react-router-dom";

const Ankauf = (props) => {
  const [device, setDevice] = useState(props.props)
  const navigate = useNavigate()

  useEffect(() => {
    setDevice(props.device)
    if (document.cookie === "") {
      navigate("/home");
      window.location.reload();
    } 
  }, [navigate, props])
  

  return (
    <div className="page-container" style={ device === "mobile" ? {"fontSize":"12pt"} : {} || device === "tablet" ? {"fontSize":"14pt"} : {} }>
      <div className="page-container-2">
      <div className="page-heading" style={ device === "mobile" ? {"fontSize":"20px"} : {} || device === "tablet" ? {"fontSize":"22pt"} : {} }>
        Sie möchten Ihr Fahrzeug verkaufen? 
        </div>

        <div className='purchasing-content-block' style={ device === "mobile"  || device === "tablet" ? {textAlign:"left"} : {textAlign:"justify"}}>
          Neben dem Verkauf von Fahrzeugen sind wir auch am Ankauf von Fahrzeugen interessiert. Kommen Sie diesbezüglich gerne auf uns zu. Wir erstellen Ihnen gerne ein kostenloses und unverbindliches Angebot. 
        </div>
        <div className='purchasing-content-block' style={ device === "mobile"  || device === "tablet" ? {textAlign:"left"} : {textAlign:"justify"}}>

        </div>
        <div className="purchasing-content-block" style={ device === "mobile"  || device === "tablet" ? {textAlign:"left", fontWeight:"bold"} : {textAlign:"justify", fontWeight:"bold"}}>
          Unser Ankaufservice umfasst:
          <ul className="purchasing-services" style={{fontWeight:"normal"}}>
            <li className="purchasing-services">sämtliche Fahrzeugtypen (auch LWK, Baustellenfahrzeuge, Motorräder, ...)</li>
            <li className="purchasing-services">sämtliche Motortypen (Benzin, Diesel, Elektro)</li>
            <li className="purchasing-services">Inzahlungnahme Ihres alten Fahrzeugs bem Kauf eines neuen</li>
            {/* <li className="purchasing-services">Import und Export</li> */}
          </ul>

          
        </div>
      </div>
    </div>
  );
};


  
export default Ankauf;