import React, {useEffect, useState} from "react";
import { slide as Menu } from 'react-burger-menu';
import {  NavLink, useNavigate } from "react-router-dom";

const urlBasePath= process.env.REACT_APP_URL_BASE_PATH

const Props = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [disabled, setDisabled] = useState(false)
  const navigate = useNavigate()

  const handleCloseMenu = (e) => {
    setIsMenuOpen(false);
    document.querySelector("[href='/fahrzeuge']").className = `sidebar-link`;
    document.querySelector("[href='/bewertungen']").className = `sidebar-link`;

    if ( document.cookie === "" && e.target.href === urlBasePath + "/home") {
      navigate("/home");
      window.location.reload();
    }
  };

  const handleStateChange = (state) => {
    // console.log(state)
    if (state === true) {
      state = {isOpen: true}
      setIsMenuOpen(state.isOpen)
    } else {
      setIsMenuOpen(state.isOpen);
    }
  };

  useEffect(() => {
    if (props.props) {
      handleStateChange(true)
      props.stateChanger(false)
    }

    if (props.device === "mobile" || props.device === "tablet") {
      const element = document.querySelector("div.site-mobile")
      if (element.style.opacity !== "1") {
        setDisabled(false)
        document.body.style.overflow = "hidden";
      } else {
        setDisabled(true)
      }
    } else if (props.device === "desktop") {
      const element = document.querySelector("div.site-desk")
      if (element.style.opacity !== "1") {
        setDisabled(false)
        document.body.style.overflow = "hidden";
      } else {
        setDisabled(true)
      }
    }
  }, [props]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
      return () => {
          document.body.style.overflow = "scroll"
      };
    }
  }, [isMenuOpen]);


  return (
      <div className={ props.device === "mobile" ? "mobile-menu-wrap" : "tablet-menu-wrap"} disabled={disabled}>
        <Menu right isOpen={isMenuOpen} onStateChange={handleStateChange}>
          <bm-item>
            <NavLink className="sidebar-link" onClick={() => handleCloseMenu()} to="/home" >Home</NavLink> 
          </bm-item>
          <bm-item>
            <NavLink className="sidebar-link" onClick={() => handleCloseMenu()} to="/fahrzeuge" >Fahrzeuge</NavLink>
          </bm-item>
          <bm-item>
            <NavLink className="sidebar-link" onClick={() => handleCloseMenu()} to="/bewertungen">Bewertungen</NavLink> 
          </bm-item>
          <bm-item>
            <NavLink className="sidebar-link" onClick={() => handleCloseMenu()} to="/about" >Unternehmen</NavLink> 
          </bm-item>
          <bm-item>
            <NavLink className="sidebar-link" onClick={() => handleCloseMenu()} to="/ankauf" >Ankauf</NavLink> 
          </bm-item>
          <bm-item>
            <NavLink className="sidebar-link" onClick={() => handleCloseMenu()} to="/kontakt" >Kontakt</NavLink>
          </bm-item>
      </Menu>
    </div>
  );
};

export default Props;
