import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import ImageGallery from "react-image-gallery";
import {FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { RiFullscreenLine, RiCloseLine } from "react-icons/ri";

const VehicleDetails = (props) => {
  const targetRef = useRef();
  // eslint-disable-next-line
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });
  const [device, setDevice] = useState(props.props)
  const navigate = useNavigate()

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, []);


  useEffect(() => {
    setDevice(props.device)
    if (document.cookie === "") {
      navigate("/home");
      window.location.reload();
    } 
  }, [navigate, props])

  let location = useLocation();


  if (location.state !== null) {
    var myState = location.state
    if (typeof myState === 'string'){
      var myURL = myState.slice(myState.indexOf("fahrzeuge") - 1,myState.indexOf("position") - 1)
      var myInputLocation = parseInt(myState.slice(myState.indexOf("position") +8,))
      window.onpopstate = () => {
        // console.log("setting document overflow")
        document.body.style.overflow = "scroll"
        navigate(myURL, { state: myInputLocation});
      }
    }
  }

  

  if (device === undefined) {
    // document.getElementsByClassName("navbar_mobile")[0].scrollIntoView({behavior: 'auto'})
  } else if (device === "mobile") {
    document.getElementsByClassName("page-container")[0].scrollIntoView({behavior: 'auto'})
    // document.getElementsByClassName("navbar_mobile")[0].scrollIntoView({behavior: 'auto'})
  } else if (device === "tablet") {
    document.getElementsByClassName("page-container")[0].scrollIntoView({behavior: 'auto'})
    // document.getElementsByClassName("navbar_mobile")[0].scrollIntoView({behavior: 'auto'})
  } else {
    document.getElementsByClassName("page-container")[0].scrollIntoView({behavior: 'auto'})
    // document.getElementsByClassName("navbar")[0].scrollIntoView({behavior: 'auto'})
  }

  const { FahrzeugID } = useParams();
  const apiBasePath= process.env.REACT_APP_API_BASE_PATH
  const [details, setDetails] = useState([]);
  const b64toBlob = (myFetch) => fetch(`${myFetch}`).then(res => res.blob())
  const [imagesSmall, setImagesSmall] = useState([]);
  const [imageInitial, setImageInitial] = useState([])
  const [totalImages, setTotalImages] = useState([])
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [counterSeen, setCounterSeen] = useState([])
  var myKeyDown = 'undefined'
  var keyValue = useRef(null);
  // eslint-disable-next-line
  const [galleryInput, setGalleryInput] = useState([]);
  

  useEffect(() => {
    //set Fahrzeuge in Navbar Active
    document.querySelector("[href='/fahrzeuge']").className = `active`;
    const getVehicleDetails = () => {
      axios.get(apiBasePath + '/vehicleDetails/' + FahrzeugID)
        .then((value) => {
          setDetails(value.data[0])
        })
        .catch((err) => {
          console.log(err + "error loading vehicle detail data")
        })
    }
    getVehicleDetails();

    
  }, [FahrzeugID, apiBasePath]);

  useEffect(() => {
    //get initial set of Image
    const fetchInitialImage = async () =>{
      setLoading(true);
      //get data from specific ip
      // const myInitialImage = await axios.get('http://' + window.location.hostname + ':8081/images/large/first/' + FahrzeugID);
      //get data from localhost
      const myInitialImage = await axios.get(apiBasePath + '/images/large/first/' + FahrzeugID);
      for ( let i = 0; i < myInitialImage.data.length; i++ ) {
        myInitialImage.data[i].base64string = await URL.createObjectURL(await b64toBlob(myInitialImage.data[i].base64string));
      }
      setImageInitial(myInitialImage.data);
      setLoading(false);
    }
    fetchInitialImage();

    //get all small images
    const fetchSmallImages = async () => {
      setLoading(true);
      //get data from specific ip
      // const imagesSmall = await axios.get('http://' + window.location.hostname + ':8081/images/small/' + FahrzeugID);
      //get data from localhost
      const imagesSmall = await axios.get(apiBasePath + '/images/small/' + FahrzeugID);
      for ( let i = 0; i < imagesSmall.data.length; i++ ) {
        imagesSmall.data[i].base64string = await URL.createObjectURL(await b64toBlob(imagesSmall.data[i].base64string));
      }
      setImagesSmall(imagesSmall.data);
      setTotalImages(imagesSmall.data.length)
      setLoading(false);
    }
    fetchSmallImages();


    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    }

    // eslint-disable-next-line
  }, [FahrzeugID, apiBasePath]);


  useEffect(() => {
    const initialGalleryInput = (() => {
      for (var i = 0; i < imagesSmall.length; i++) {
        if (counterSeen.includes(imagesSmall[i].position) !== true) {
          galleryInput.push({position:imagesSmall[i].position})
          counterSeen.push(imagesSmall[i].position)
        }
        // eslint-disable-next-line
        var myIndex = galleryInput.findIndex(({ position }) => position === imagesSmall[i].position)
        galleryInput[myIndex]["thumbnail"] = imagesSmall[i].base64string
        if (imageInitial[0] === undefined) {

        } else if (imagesSmall[i].position === 1) {
          galleryInput[myIndex]["original"] = imageInitial[0].base64string
        } else {
          galleryInput[myIndex]["original"] = imagesSmall[i].base64string
        }
      }
    })
    initialGalleryInput();
    // eslint-disable-next-line
  }, [imageInitial, imagesSmall])



  const handleKeyDown = (e) => {
    if (myKeyDown === 1) {
    } else {
      if (myKeyDown === 'undefined') {
        myKeyDown = String(e.keyCode)
        keyValue = myKeyDown
        // setKeyValue(myKeyDown)
      } else {
        //get last element and compare
        var lastExistingElement = Number(keyValue.split(",").pop())
        if (lastExistingElement === e.keyCode) {
          myKeyDown = myKeyDown + ', ' + String(e.keyCode)
          keyValue = myKeyDown
        } else {
          myKeyDown = String(e.keyCode)
          keyValue = myKeyDown
        }
      }
      fetchAdditionalImageFromKeyDown(keyValue);
    }
  }


  const myRenderRightNav = () => {
    return (
      <button
        type='button'
        className='image-gallery-icon image-gallery-right-nav'
        onClick={onClickSlideRight}
      >
        <FaAngleRight size={50}/> 
      </button>
    );
  }

  const myRenderLeftNav = () => {
    return (
      <button
        type='button'
        className='image-gallery-icon image-gallery-left-nav'
        onClick={onClickSlideLeft}
      >
        <FaAngleLeft size={50}/> 
      </button>
    );
  }

  const fetchAdditionalImage = (imageIndex) => {
    //get data from specific ip
    // return axios.get('http://' + window.location.hostname + ':8081/images/large/' + FahrzeugID + "/" + imageIndex)
    return axios.get(apiBasePath + '/images/large/' + FahrzeugID + "/" + imageIndex)
  } 

  const updateGallery = (targetIndex, myBlob) => {
    galleryInput[targetIndex]["original"] = URL.createObjectURL(myBlob)
    return new Promise(function(resolve, reject) {
      resolve('start of new Promise');
      reject(new Error('Something is not right!'));
    });
  }


  const slideshowref = useRef();
  const onClickSlideRight = () => {
    var currentIndex = slideshowref.current.getCurrentIndex()
    var targetIndex = ''
    if (currentIndex === totalImages - 1) {
      targetIndex = 0
    } else {
      targetIndex = currentIndex + 1
    }
    if (galleryInput[targetIndex]["original"] === galleryInput[targetIndex]["thumbnail"]) {
      fetchAdditionalImage((targetIndex))
        .then((myAdditionalImage) => {
          b64toBlob(myAdditionalImage.data[0].base64string)
          .then((myBlob) => {
            updateGallery(targetIndex, myBlob)
            .then(() => {
              slideshowref.current.slideToIndex(targetIndex)
            })
          })
        })
    } else {
      slideshowref.current.slideToIndex(targetIndex)
    }
  }


  const onClickSlideRight2 = () => {
    var currentIndex = slideshowref.current.getCurrentIndex()
    if (galleryInput[currentIndex]["original"] === galleryInput[currentIndex]["thumbnail"]) {
      fetchAdditionalImage((currentIndex + 1))
        .then((myAdditionalImage) => {
          b64toBlob(myAdditionalImage.data[0].base64string)
          .then((myBlob) => {
            updateGallery(currentIndex, myBlob)
            .then(() => {
              slideshowref.current.slideToIndex(currentIndex)
            })
          })
        })
    } else {
      slideshowref.current.slideToIndex(currentIndex)
    }
  }

  const onClickSlideLeft = () => {
    var currentIndex = slideshowref.current.getCurrentIndex()
    var targetIndex = ''
    if (currentIndex === 0) {
      targetIndex = totalImages - 1
    } else {
      targetIndex = currentIndex - 1
    }
    if (galleryInput[targetIndex]["original"] === galleryInput[targetIndex]["thumbnail"]) {
      fetchAdditionalImage((targetIndex + 1))
        .then((myAdditionalImage) => {
          b64toBlob(myAdditionalImage.data[0].base64string)
          .then((myBlob) => {
            updateGallery(targetIndex, myBlob)
            .then(() => {
              slideshowref.current.slideToIndex(targetIndex)
            })
          })
        })
    } else {
      slideshowref.current.slideToIndex(targetIndex)
    }
  }

  const onClickSlideLeft2 = () => {
    var currentIndex = slideshowref.current.getCurrentIndex()
    var targetIndex = ''
    if (currentIndex === totalImages - 1) {
      targetIndex = totalImages - 1
    } else {
      targetIndex = currentIndex 
    }
    if (galleryInput[targetIndex]["original"] === galleryInput[targetIndex]["thumbnail"]) {
      fetchAdditionalImage((targetIndex + 1))
        .then((myAdditionalImage) => {
          b64toBlob(myAdditionalImage.data[0].base64string)
          .then((myBlob) => {
            updateGallery(targetIndex, myBlob)
            .then(() => {
              slideshowref.current.slideToIndex(targetIndex)
            })
          })
        })
    } else {
      slideshowref.current.slideToIndex(targetIndex)
    }
  }

  const myThumbnailClick = (event, targetIndex) => {
    if (galleryInput[targetIndex]["original"] === galleryInput[targetIndex]["thumbnail"]) {
      fetchAdditionalImage((targetIndex + 1))
        .then((myAdditionalImage) => {
          b64toBlob(myAdditionalImage.data[0].base64string)
          .then((myBlob) => {
            updateGallery(targetIndex, myBlob)
            .then(() => {
              slideshowref.current.slideToIndex(targetIndex)
            })
          })
        })
    } else {
      slideshowref.current.slideToIndex(targetIndex)
    }
  }

  const myRenderFullscreenButton = (onClick, isFullscreen) => {
    return (
      <button 
        className="image-gallery-icon image-gallery-fullscreen-button"
        onClick={() => myClick(onClick, isFullscreen)}
      >
        <RiFullscreenLine size={36} />
      </button>
    )
  }

  function myClick(onClick,isFullscreen) {
    onClick();
    if (device==="desktop") {
      if (!isFullscreen) {
        document.body.style.overflow = "hidden";
        return () => {
          document.body.style.overflow = "scroll"
        }; 
      } else if (isFullscreen) {
        document.body.style.overflow = "scroll";
        return () => {
          document.body.style.overflow = "hidden"
      };
      }
    } else if (device==="mobile" || device==="tablet") {
      if (!isFullscreen && document.querySelector("div.bm-burger-button") !== null) {
        document.body.style.overflow = "hidden";
        document.querySelector("div.bm-burger-button").style.display = 'none';
        return () => {
            document.body.style.overflow = "scroll"
        }; 
      } else if (isFullscreen) {
        document.body.style.overflow = "scroll";
        document.querySelector("div.bm-burger-button").style.display = 'block';
        return () => {
            document.body.style.overflow = "hidden"
        };
      }
    } 
  }

  function myClick2() {
    document.getElementsByClassName('image-gallery-icon image-gallery-fullscreen-button')[0].click();
  }

  const fetchAdditionalImageFromKeyDown = (() => {
    if (keyValue.current === null) {
      var currentKeyDown = 1
    } else {
      // eslint-disable-next-line
      var currentKeyDown = Number(keyValue.split(",").pop())
    }
    if (currentKeyDown === 39) {
      var currentIndex = slideshowref.current.getCurrentIndex()
      var myTotalImages = galleryInput.length
      if (currentIndex === myTotalImages - 1) {
        var targetIndex = 0
      } else {
        // eslint-disable-next-line
        var targetIndex = currentIndex + 1
      }
      if (galleryInput[targetIndex]["original"] === galleryInput[targetIndex]["thumbnail"]) {
        fetchAdditionalImage((targetIndex + 1))
          .then((myAdditionalImage) => {
            b64toBlob(myAdditionalImage.data[0].base64string)
            .then((myBlob) => {
              updateGallery(targetIndex, myBlob)
              .then(() => {
                slideshowref.current.slideToIndex(targetIndex)
              })
            })
          })
      } else {
        slideshowref.current.slideToIndex(targetIndex)
      }
    } else if (currentKeyDown === 37) {
      // eslint-disable-next-line 
      var currentIndex = slideshowref.current.getCurrentIndex()
      // eslint-disable-next-line 
      var myTotalImages = galleryInput.length
      if (currentIndex === 0) {
        // eslint-disable-next-line 
        var targetIndex = myTotalImages - 1
      } else {
        // eslint-disable-next-line 
        var targetIndex = currentIndex - 1
      }
      if (galleryInput[targetIndex]["original"] === galleryInput[targetIndex]["thumbnail"]) {
        fetchAdditionalImage((targetIndex + 1))
          .then((myAdditionalImage) => {
            b64toBlob(myAdditionalImage.data[0].base64string)
            .then((myBlob) => {
              updateGallery(targetIndex, myBlob)
              .then(() => {
                slideshowref.current.slideToIndex(targetIndex)
              })
            })
          })
      } else {
        slideshowref.current.slideToIndex(targetIndex)
      }
    }

    //close fullscreen image gallery when escape button is clicked
    if (currentKeyDown === 27 && document.body.style.overflow === "hidden") {
      myClick2();
    }
    if (device==="desktop") {
      // on initial page load --> hide button
      if (document.body.style.overflow === "") {
        //don't display the button 
      }
      // if in full screen (determined by scrolling being disabled) --> display button
      else if (document.body.style.overflow === "hidden") {
        return (
          <button className="closeButton" onClick={myClick2} style={{background:"rgba(0, 0, 0, 0.4)", position:"absolute", zIndex:4, height:"2.6rem", width:"2.6rem", left:"5px", top:"5px", border:"none", padding:"0px"}}>
            <RiCloseLine style={{ color:"#ffffff", height:"2.6rem", width:"2.6rem"}}/>
          </button>)
      // if not in full screen (determined by scrolling being enabled)--> hide button
      } else if (document.body.style.overflow === "scroll") {
        //don't display the button 
      }

      //handle keydown event for escape button



    } else {
      if (document.querySelector("div.bm-burger-button") === null) {   
        //do nothing  
      } else if (document.querySelector("div.bm-burger-button").style.display === "none") {
        return (
          <button onClick={myClick2} style={{background:"rgba(0, 0, 0, 0.4)", position:"absolute", zIndex:4, height:"2rem", width:"2rem", left:"0px", border:"none", padding:"0px"}}>
              <RiCloseLine style={{ color:"#ffffff", height:"2rem", width:"2rem"}}/>
          </button>)
      } else if (document.querySelector("div.bm-burger-button").style.display === "") {
      } 
    }
  })

  var globalVariable
  const touchStarted = ((e) => {
    globalVariable = e.targetTouches[0].clientX
  })



  const minSwipeDistance = 5 

  const touchEnded = ((e) => {
    var touchEnd = e.changedTouches[0].clientX
    var touchStart = globalVariable
    var distance = touchStart - touchEnd
    var isLeftSwipe = distance > minSwipeDistance
    var isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) {
      onClickSlideRight2();
    } else if (isRightSwipe) {
      onClickSlideLeft2();
    }
  })

  return (
    <div className="page-container" style={ device === "mobile" ? {"fontSize":"12pt"} : {} || device === "tablet" ? {"fontSize":"14pt"} : {} }>
      <div className="page-container-2">
        <div className="page-heading-vehicle" style={ device === "mobile" ? {"fontSize":"20px"} : {} || device === "tablet" ? {"fontSize":"22pt"} : {} }>
          {details.vehicleName}
        </div>
        <div className="vehicle-main-info" ref={targetRef} style={device==="mobile" || device==="tablet" ? {display:"block"} : {}}>
          <div className="image-gallery-container" style={device==="mobile" || device==="tablet" ? {width:"100%"} : {}}>
          <ImageGallery 
            ref={slideshowref}
            items={galleryInput} 
            loading={"lazy"}
            onThumbnailClick={myThumbnailClick}
            renderRightNav={myRenderRightNav}
            renderLeftNav={myRenderLeftNav}
            renderFullscreenButton={myRenderFullscreenButton}
            showIndex={true}
            showPlayButton={false}
            disableKeyDown={true}
            renderCustomControls={fetchAdditionalImageFromKeyDown}
            swipeThreshold={50}
            onTouchStart={touchStarted}
            onTouchEnd={touchEnded}
            useBrowserFullscreen={false}
          />
          {/* <p>{dimensions.width}</p>
          <p>{dimensions.height}</p> */}
          </div>
          {
            (() => {
              if (device==="mobile"){
                return (
                  <>
                    <div className="vehilce-main-info-container-mobile">
                      <div className="vehilce-main-info-container-mobile-left-ragged">
                      <div className="vehicle-main-info-attribute" style={{fontWeight:"bold"}}>
                        {details.vehicleType} 
                      </div>
                      <div className="vehicle-main-info-attribute" style={{fontWeight:"bold"}}>
                        <br/>{details.vehicleAvailability}
                      </div>
                      {
                        (() => {
                          if (details.vehicleAttributesMain === undefined){
                            return <div>loading</div>
                          } else {
                            return (
                              details.vehicleAttributesMain.map((attribute) => {
                                return <div className="vehicle-main-info-attribute" key={attribute}>{attribute}</div>
                              })
                            )
                          }
                        })()
                      }
                      <div className="vehicle-main-info-attribute">
                        <br/>{details.vehicleCountryVersionOrDamaged}
                      </div>
                      </div>
                      <div className="vehilce-main-info-container-mobile-right-ragged">
                        {
                          (() => {
                            if (details.netGiven){
                              return (
                                <div className="price-mobile">
                                  <div style={{fontSize:"12pt", fontWeight: 'bold', paddingBottom:"6px"}}>{details.grossPrice.toLocaleString("de-DE")} € (brutto) </div>
                                  <div style={{fontSize:"12pt"}}> {details.netPrice.toLocaleString("de-DE")} € (netto)</div>
                                  <div style={{fontSize:"12pt"}}> {details.tax} </div>
                                </div>
                              )
                            } 
                            if (!details.netGiven && details.grossPrice !== undefined){
                              return (
                                <div className="price-mobile" style={{fontSize:"16pt", fontWeight: 'bold', paddingBottom:"10px"}}>
                                  {details.grossPrice.toLocaleString("de-DE")} €
                                </div>
                              )
                            }
                          })()
                        }
                      </div>
                    </div>
                  </>
                )
              } else if (device === "tablet") {
                return (
                  <>
                    <div className="vehilce-main-info-container-mobile">
                      <div className="vehilce-main-info-container-mobile-left-ragged">
                      <div className="vehicle-main-info-attribute" style={{fontWeight:"bold"}}>
                        {details.vehicleType} 
                      </div>
                      <div className="vehicle-main-info-attribute" style={{fontWeight:"bold"}}>
                        <br/>{details.vehicleAvailability}
                      </div>
                      {
                        (() => {
                          if (details.vehicleAttributesMain === undefined){
                            return <div>loading</div>
                          } else {
                            return (
                              details.vehicleAttributesMain.map((attribute) => {
                                return <div className="vehicle-main-info-attribute" key={attribute}>{attribute}</div>
                              })
                            )
                          }
                        })()
                      }
                      <div className="vehicle-main-info-attribute">
                        <br/>{details.vehicleCountryVersionOrDamaged}
                      </div>
                      </div>
                      <div className="vehilce-main-info-container-mobile-right-ragged">
                        {
                          (() => {
                            if (details.netGiven){
                              return (
                                <div className="price-mobile">
                                  <div style={{fontSize:"12pt", fontWeight: 'bold', paddingBottom:"6px"}}>{details.grossPrice.toLocaleString("de-DE")} € (brutto) </div>
                                  <div style={{fontSize:"12pt"}}> {details.netPrice.toLocaleString("de-DE")} € (netto)</div>
                                  <div style={{fontSize:"12pt"}}> {details.tax} </div>
                                </div>
                              )
                            } 
                            if (!details.netGiven && details.grossPrice !== undefined){
                              return (
                                <div className="price-mobile" style={{fontSize:"16pt", fontWeight: 'bold', paddingBottom:"10px"}}>
                                  {details.grossPrice.toLocaleString("de-DE")} €
                                </div>
                              )
                            }
                          })()
                        }
                      </div>
                    </div>
                  </>
                )
              }
              else {
                return (
                  <>
                  <div className="vehicle-main-info-left-ragged" style={{marginLeft:"24px", width:"100%"}}>
                    <div className="vehicle-main-info-attribute" style={{fontWeight:"bold"}}>
                      {details.vehicleType} 
                    </div>
                  <div className="vehicle-main-info-attribute" style={{fontWeight:"bold"}}>
                    <br/>{details.vehicleAvailability}
                  </div>
                  {
                    (() => {
                      if (details.vehicleAttributesMain === undefined){
                        return <div>loading</div>
                      } else {
                        return (
                          details.vehicleAttributesMain.map((attribute) => {
                            return <div className="vehicle-main-info-attribute" key={attribute}>{attribute}</div>
                          })
                        )
                      }
                    })()
                  }
                  <div className="vehicle-main-info-attribute">
                    <br/>{details.vehicleCountryVersionOrDamaged}
                  </div>
                </div>
                <div className="vehicle-main-info-right-ragged">
                {
                  (() => {
                    if (details.netGiven){
                      return (
                        <div className="price">
                          <div style={{fontSize:"16pt", fontWeight: 'bold', paddingBottom:"10px"}}>{details.grossPrice.toLocaleString("de-DE")} € (brutto) </div>
                          <div style={{fontSize:"13pt"}}> {details.netPrice.toLocaleString("de-DE")} € (netto)</div>
                          <div style={{fontSize:"13pt"}}> {details.tax} </div>
                        </div>
                      )
                    } 
                    if (!details.netGiven && details.grossPrice !== undefined){
                      return (
                        <div className="price" style={{fontSize:"16pt", fontWeight: 'bold', paddingBottom:"10px"}}>
                          {details.grossPrice.toLocaleString("de-DE")} €
                        </div>
                      )
                    }
                  })()
                }
              </div>
              </>
              )
              }
            })()
          }
        </div>
        <div className="technical-attributes-heading">
          Technische Daten
          <div className="vehicle-main-info" style={{fontWeight:"normal", paddingTop:"20px", fontSize:"13pt"}}>
            <div className="vehicle-main-info-left-ragged" style={{width:"100%"}}>
              {
                (() => {
                  if(details.vehicleTechnicalDetails === undefined) {
                    return <div>loading</div>
                  } else {
                    return (
                      <table className="technical-attributes">
                        <tbody>                
                        {details.vehicleTechnicalDetails.map((attribute, index) => {
                          return (
                            <tr className="technical-attributes-table-line" key={index}>
                              <th className="technical-attributes-table-cell">
                                {attribute.Attribute} 
                              </th>
                              <th className="technical-attributes-table-cell">
                                {attribute.Value}
                              </th>
                            </tr>
                          )
                        })}    
                        </tbody>   
                      </table>
                    )
                  }
                })()
              }
            </div>
            {/* <div className="vehicle-main-info-right-ragged">
              {
                (() => {
                  if (details.vehicleFuelConsumption === undefined){
                    return <div>loading</div>
                  } else {
                    return (
                      <table className="technical-attributes"  style={{textAlign:"left"}}>
                        <tbody>     
                          {details.vehicleFuelConsumption.map((attribute,index) => {
                            return(
                              <tr className="technical-attributes-table-line" key={index} style={{verticalAlign:"top"}}>
                                <th className="technical-attributes-table-cell">
                                  <span className="fuelConsumption">
                                    {attribute.Attribute}
                                  </span> 
                                </th>
                                <th className="technical-attributes-table-cell" style={{padding:"0px 0px 0px 0px"}}>
                                  {attribute.Value.split("<br>").map((item,itemIndex) => {
                                    return (
                                        <span className="fuelConsumption" key={itemIndex}>
                                          {item}
                                          <br />
                                        </span>
                                    ) 
                                  })}
                                </th>
                              </tr>
                            )
                          })}
                        </tbody>   
                      </table>
                    )
                  }
                })()
              }
            </div> */}
          </div>
        </div>
        <div className="technical-attributes-heading">
          Ausstattung
          <div className="vehicle-main-info" style={{fontWeight:"normal", paddingTop:"20px", fontSize:"13pt", borderBottom:"solid", paddingBottom:"20px"}}>
            {
              (() => {
                if(details.vehicleFeatures === undefined) {
                  return <div>loading</div>
                } else {
                  return(
                    <table className="vehicleAttributeTable">
                      <tbody className="vehicleAttributeTableBody">
                        {
                          (() => {
                            var elementCount = details.vehicleFeatures.length / 4
                            var elements = details.vehicleFeatures
                            return(
                              details.vehicleFeatures.map((attribute,index) => {
                                if (index === 0) {
                                  return(
                                    <tr className="vehicleAttributeRow" key={index}>
                                      <th className="vehicleAttributeCell">
                                        <ul className="vehicleAttributeList">
                                          {elements.map((innerAttribute, innerIndex) => {
                                            if (innerIndex < elementCount) {
                                              return <li className="vehicleAttributeElement" key={innerIndex}>{innerAttribute}</li>
                                            } else {
                                              return null
                                            }
                                          })}
                                        </ul>
                                      </th>
                                    </tr>
                                  )
                                } else if (index === 1){
                                  return(
                                    <tr className="vehicleAttributeRow" key={index}>
                                      <th className="vehicleAttributeCell">
                                        <ul className="vehicleAttributeList">
                                          {elements.map((innerAttribute, innerIndex) => {
                                            if (innerIndex <= elementCount * 2 &&  innerIndex > elementCount) {
                                              return <li className="vehicleAttributeElement" key={innerIndex}>{innerAttribute}</li>
                                            } else {
                                              return null
                                            }
                                          })}
                                        </ul>
                                      </th>
                                    </tr>
                                  )
                                } 
                                else if (index === 2){
                                  return(
                                    <tr className="vehicleAttributeRow" key={index}>
                                      <th className="vehicleAttributeCell">
                                        <ul className="vehicleAttributeList">
                                          {elements.map((innerAttribute, innerIndex) => {
                                            if (innerIndex <= elementCount * 3 &&  innerIndex > elementCount * 2) {
                                              return <li className="vehicleAttributeElement" key={innerIndex}>{innerAttribute}</li>
                                            } else {
                                              return null
                                            }
                                          })}
                                        </ul>
                                      </th>
                                    </tr>
                                  )
                                } 
                                else if (index === 3){
                                  return(
                                    <tr className="vehicleAttributeRow" key={index}>
                                      <th className="vehicleAttributeCell">
                                        <ul className="vehicleAttributeList">
                                          {elements.map((innerAttribute, innerIndex) => {
                                            if (innerIndex <= elementCount * 4 &&  innerIndex > elementCount * 3) {
                                              return <li className="vehicleAttributeElement" key={innerIndex}>{innerAttribute}</li>
                                              } else {
                                              return null
                                            }
                                          })}
                                        </ul>
                                      </th>
                                    </tr>
                                  )
                                } 
                                else {
                                  return null
                                }
                              })
                            )
                          })()
                        }
                      </tbody>     
                    </table>
                  )
                }
              })()
            }
          </div>
        </div>
        <div className="technical-attributes-heading">
          Fahrzeugbeschreibung
          <div className="vehicle-main-info-fixed" style={{fontWeight:"normal", paddingTop:"20px", fontSize:"13pt", borderBottom:"solid", paddingBottom:"20px"}}>
            {
              (() => {
                var myDescription = String(details.vehicleDescription).split("<br>")
                if (myDescription === undefined){
                  return <div>loading</div>
                } else {
                  return(
                    myDescription.map((attribute, index) => {
                      if (attribute === "") {
                        return (
                          <br key={index}></br>
                        )
                      } 
                      else if (attribute.includes("Unser Service")) {
                        return (
                          <div className="vehicleDescriptionLine "key={index} style={{fontWeight:"bold"}}>
                            {attribute}
                          </div>
                        )
                      } 
                      else {
                        return (
                          <div className="vehicleDescriptionLine" key={index}>
                            {attribute}
                          </div>
                        )
                      }
                    })
                  )
                }
              })()
            }
          </div>
        </div>
        <div className="technical-attributes-heading">
          {/* Eigenschaften */}
          <div className="vehicle-all-attributes">
            {
              (() => {
                var atttributes = details.vehicleAdditionalAttributes
                if(atttributes === undefined) {
                  return <div>loading</div>
                } else {
                  return (
                    atttributes.map((attribute, index) => {
                      var myAttribute = attribute
                      return (  
                        (() => {
                          if (myAttribute === undefined) {
                            return <div>def</div>
                          } else {
                            var myKey = Object.keys(attribute)
                            var myObj = myAttribute[myKey]
                            return (
                              <div className="vehicleAdditionalAttributes" key={index}> {myKey}
                                <ul className="vehicleAdditionalAttributeList">
                                  {myObj.map((innerAttribute,innerIndex) => {
                                    return <li className="vehicleAdditionalAttributeElement" key={innerIndex}>{myObj[innerIndex]}</li>
                                  })}
                                </ul>
                              </div>
                            )
                          }
                        })()
                      )
                    })
                  )
                }
              })()
            }
          </div>
        </div>
      </div>
    </div>
  );
};
  
export default VehicleDetails;

 
