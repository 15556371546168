import React, { useEffect, useState } from "react";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
  
const Cookies = (props) => {
  const [device, setDevice] = useState()
  const [triggeredByEvent, setTriggeredByEvent] = useState(false)
  // const [pointerEvents, setPointerEvents] = useState(true)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setDevice(props.device)
    const btn = document.querySelector("button.customCookieButton");

    function setState(event) {
      // console.log("set state fired")
      setTriggeredByEvent(true)
    }

    btn.addEventListener("click", setState);

    if (!triggeredByEvent) {
      CookieConsent.hide()
    } 
    // setTriggeredByEvent(false)


    window.addEventListener('cc:onModalHide', ({detail}) => {
      // console.log("modal closure fired")
      setDisabled(false)
  });
  
    return () => {
        window.removeEventListener('click', setState);
        window.removeEventListener('cc:onModalHide', setState);
    };

  }, [props])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function showConsentModal() {
    CookieConsent.show(true);
    document.body.style.position = "fixed"
    setDisabled(true)
  }

  function showPreferencesModal() {
    CookieConsent.showPreferences();
    document.body.style.position = "fixed"
    setDisabled(true)
  }

  return (
    <div className="page-container"  style={ device === "mobile" ? {"fontSize":"12pt"} : {} || device === "tablet" ? {"fontSize":"14pt"} : {} }>
      <div className="page-container-2">
        <div className="page-heading" style={ device === "mobile" ? {"fontSize":"20px"} : {} || device === "tablet" ? {"fontSize":"22pt"} : {} }>
          Cookie-Richtlinie
        </div>
        <div className="impressum-content-block" style={ device === "mobile" || device === "tablet" ? {textAlign:"left"} : {textAlign:"justify"}}>
        Wir benötigen Ihre Zustimmung, bevor Sie unsere Website besuchen können. Wir verwenden Cookies und andere Technologien auf userer Website. Einige von ihnen sind essenziell, während andere uns helfen diese Website und ihre Erfahrung mit dieser Website zu verbessern. Dabei können personenbezogene Daten können verarbeitet werden (z.B. IP-Adressen). Des Weiteren binden wir Services von Drittparteien ein, wie z.B. Google Maps, wofür wir Ihre Zustimmung benötigen. Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer <a style={{wordBreak:"break-all"}} href="https://www.autohaus-kauschinger.de/datenschutz">Datenschutzerklärung</a> sowie in unserem <a style={{wordBreak:"break-all"}} href="https://www.autohaus-kauschinger.de/Impressum">Impressum</a>. Sie können Ihre Auswahl jederzeit auf dieser Seite widerrufen oder anpassen. Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu freiwilligen Diensten geben möchten, müssen Sie Ihre Erziehungsberechtigten um Erlaubnis bitten.
        <br></br>
        <br></br>

        Auf unserer Website verwenden wir technisch notwendige Cookies sowie Services von Drittparteien (Google Maps). Technisch notwendige Cookies sind für die Nutzung unserer Webiste zwingend erforderlich, z.B. um die Navigation auf unserer Website zu ermöglichen. Services von Drittparteien sind optional und können abgelehnt werden. Gesetzte Cookies sind für 180 Tage gültig. Nach Ablauf dieser Zeit müssen Sie ihre Cookie Präferenzen erneut festlegen.
        <br></br>
        <br></br>
        Unsere Website verzichtet auf die Nutzung von performance, analytics, und advertising cookies. Im Allgemeinen richtet sich unser Cookie-Policy nach §25 TTDSG.
        <br></br>
        <br></br>
          <div>
            Hier können Sie ihre Cookie Präferenzen sowie ihre Datenschutzeinstellungen anpassen:
          </div>
          <br></br>
          <div> 
            { device === "mobile" || device === "tablet" ? 
              <>
                <button className="customCookieButton" type="button" data-cc="show-consentModal" onClick={showConsentModal} disabled={disabled}>Cookie Präferenzen</button>
                <div style={{marginTop:"10px"}}></div>
                <button className="customCookieButton" type="button" data-cc="show-preferencesModal" onClick={showPreferencesModal} disabled={disabled}>Individuelle Datenschutzeinstellungen</button>
              </>
              :<>
                <button className="customCookieButton" type="button" data-cc="show-consentModal" onClick={showConsentModal} disabled={disabled}>Cookie Präferenzen</button>
                <button className="customCookieButton" type="button" data-cc="show-preferencesModal" onClick={showPreferencesModal} disabled={disabled}>Individuelle Datenschutzeinstellungen</button>
              </>
            }

          </div>
        </div>
      </div>
    </div>
  );
};
  
export default Cookies;

// "Zur bedarfsgerechten Gestaltung unserer Webseiten erstellen wir pseudonyme Nutzungsprofile mit Hilfe von Google Analytics. Google Analytics verwendet Targeting Cookies, die auf Ihrem Endgerät gespeichert und von uns ausgelesen werden können. Auf diese Weise sind wir in der Lage, wiederkehrende Besucher zu erkennen und als solche zu zählen und zu erfahren, wie häufig unsere Webseiten von unterschiedlichen Nutzern aufgerufen wurden. Die Datenverarbeitung erfolgt auf der Grundlage von Art. 6 Abs. 1 lit. a DS-GVO (Einwilligung).
// Die durch das Cookie erzeugten Informationen über Ihre Benutzung unserer Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Da wir die IP-Anonymisierung auf unserer Webseite aktiviert haben, wird Ihre IP-Adresse von Google jedoch zuvor innerhalb von Mitgliedstaaten der Europäischen Union gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und erst dort gekürzt (weitere Informationen zu Zweck und Umfang


// der Datenerhebung erhalten Sie zB unter https://policies.google.com/privacy?hl=de&gl=de). Wir haben mit Google LLC (USA) zudem einen Vertrag zur Auftragsverarbeitung nach Art. 28 DS-GVO geschlossen. Google wird alle Informationen demnach nur streng zweckgebunden nutzen, um die Nutzung unserer Webseiten für uns auszuwerten und Reports über die Webseitenaktivitäten zusammenzustellen.
// Google setzt folgende Cookies, wenn Sie unsere Webseite besuchen und der Verwendung der Cookie Google Analytics einwilligen:
// Name Zweck
// _ga Das hilft uns zu zählen, wie viele Personen unsere Internetpräsentation besuchen, wenn Sie diese bereits besucht haben.
// _gid Das hilft uns zu zählen, wie viele Personen unsere Internetpräsentation besuchen, wenn Sie diese bereits besucht haben.
// _gat Dies hilft uns, die Frequenz zu verwalten, in der Anfragen für das Anzeigen einer Seite gestellt waren.
// Auslaufen [Dauer]
// [Dauer] [Dauer]
// Sie können Ihre einmal erteilte Einwilligung jederzeit widerrufen. Bitte nutzen Sie hierfür eine der folgenden Möglichkeiten:
// – Sie teilen uns mit, dass Sie Ihre Einwilligung widerrufen möchten.
// – Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Webseite vollumfänglich werden nutzen können.
// – Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung unserer Webseiten bezogenen Daten (inkl. Ihrer IP- Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link (https://tools.google.com/dlpage/gaoptout?hl=de) verfügbare Browser- Plugin herunterladen und installieren."