import React, { useEffect, useState } from "react";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

const Impressum = (props) => {

  const [device, setDevice] = useState(props.props)

  useEffect(() => {
    setDevice(props.device)
    CookieConsent.hide()
  }, [props])

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <div className="page-container" style={ device === "mobile" ? {"fontSize":"12pt"} : {} || device === "tablet" ? {"fontSize":"14pt"} : {} }>
      <div className="page-container-2">
        <div className="page-heading" style={ device === "mobile" ? {"fontSize":"20px"} : {} || device === "tablet" ? {"fontSize":"22pt"} : {} }>
          Impressum
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-first-level-heading-mobile" : "impressum-first-level-heading"}>
          1. Angaben gemäß §5 Telemediengesetz
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
        <span>Christoph Kauschinger ist ein kaufmännisches Einzelunternehmen nach Maßgabe des Handelsgesetzbuches mit Sitz in Deggendorf. <br></br> </span>
          <br></br>
          <strong>Postanschrift:</strong><br></br>
          Christoph Kauschinger <br></br>
          Hauptstraße 18 <br></br>
          94469 Deggendorf <br></br>
          Deutschland <br></br>
          <br></br>
          <strong>Kontaktdaten:</strong> <br></br>
          Email: <a href="mailto:info@autohaus-kauschinger.de?subject=Anfrage an das Autohaus Kauschinger">info@autohaus-kauschinger.de </a>  <br></br>
          Telefon: <a href="tel:+4915165874553">+49 (0) 151 65874553</a><br></br>
          Internetseite: <a style={{wordBreak:"break-all"}} href="https://www.autohaus-kauschinger.de">https://www.autohaus-kauschinger.de/</a><br></br>
          <br></br>
          <strong>Inhaber:</strong> <br></br>
          Christoph Kauschinger
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-first-level-heading-mobile" : "impressum-first-level-heading"}>
          2. Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          Umsatzsteuer-ID: DE330201768
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-first-level-heading-mobile" : "impressum-first-level-heading"}>
          3. Weitere Angaben
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          <strong>3.1. AGB:</strong> <br></br>
          Für von uns erbrachte Leistungen gelten unsere AGB. Diese werden Ihnen bei Vertragsabschluss ausgehändigt. Darin ist geregelt, dass deutsches Recht Anwendung findet und das soweit zulässig, als Gerichtsstand Deggendorf vereinbart wird. <br></br>
          <br></br>
          <strong>3.2. Hinweis zur OS-Plattform:</strong> <br></br>
          Wir sind aufgrund von Art. 14 Abs. 1 der Verordnung (EU) 524/2013 über Online- Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) gesetzlich verpflichtet, Sie auf die Europäische Online-Streitbeilegungs-Plattform (OS-Plattform) der Europäischen Kommission hinzuweisen. Sie können diese unter <a style={{wordBreak:"break-all"}} href="https://ec.europa.eu/odr">https://ec.europa.eu/odr</a> erreichen.
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-first-level-heading-mobile" : "impressum-first-level-heading"}>
          4. Haftung
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          Wir sind für die Inhalte unserer Internetseiten nach den Maßgaben der allgemeinen Gesetze, insbesondere nach § 7 Abs. 1 des Telemediengesetzes, verantwortlich. Alle Inhalte werden mit der gebotenen Sorgfalt und nach bestem Wissen erstellt und dienen lediglich rein informativen Zwecken. Soweit wir auf unseren Internetseiten mittels Hyperlink auf Internetseiten Dritter verweisen, können wir keine Gewähr für die fortwährende Aktualität, Richtigkeit und Vollständigkeit der verlinkten Inhalte übernehmen, da diese Inhalte außerhalb unseres Verantwortungsbereichs liegen und wir auf die zukünftige Gestaltung keinen Einfluss haben. Sollten aus Ihrer Sicht Inhalte gegen geltendes Recht verstoßen oder unangemessen sein, teilen Sie uns dies bitte mit.
          Die rechtlichen Hinweise auf dieser Seite sowie alle Fragen und Streitigkeiten im Zusammenhang mit der Gestaltung dieser Internetseite unterliegen dem Recht der Bundesrepublik Deutschland.
          <br></br>
          <br></br>
          Alle Angaben ohne Gewähr, Irrtümer, Eingabefehler, Änderung und Zwischenverkauf vorbehalten.
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-first-level-heading-mobile" : "impressum-first-level-heading"}>
          5. Datenschutz
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          Unsere Datenschutzhinweise finden Sie unter <a style={{wordBreak:"break-all"}} href="https://www.autohaus-kauschinger.de/datenschutz">www.autohaus-kauschinger.de/datenschutz</a>.<br></br>
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-first-level-heading-mobile" : "impressum-first-level-heading"}>
          6. Urheberrechtshinweis
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
        Die auf unserer Internetseite vorhandenen Texte, Bilder, Fotos, Videos oder Grafiken unterliegen in der Regel dem Schutz des Urheberrechts. Jede unberechtigte Verwendung (insbesondere die Vervielfältigung, Bearbeitung oder Verbreitung) dieser urheberrechtsgeschützten Inhalte ist daher untersagt. Wenn Sie beabsichtigen, diese Inhalte oder Teile davon zu verwenden, kontaktieren Sie uns bitte im Voraus unter den oben stehenden Angaben. Soweit wir nicht selbst Inhaber der benötigten urheberrechtlichen Nutzungsrechte sein sollten, bemühen wir uns, einen Kontakt zum Berechtigten zu vermitteln. 
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-first-level-heading-mobile" : "impressum-first-level-heading"}>
          7. Social-Media
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          Dieses Impressum gilt auch für folgende Social Media-Profile:<br></br>
          <br></br>
          - Mobile.de: <a style={{wordBreak:"break-all"}} href="https://home.mobile.de/KAUSCHINGERAUTOMOBILE">https://home.mobile.de/kauschingerautomobile</a><br></br>
          - Instagram: <a style={{wordBreak:"break-all"}} href="https://www.instagram.com/autohaus_kauschinger">https://www.instagram.com/autohaus_kauschinger</a><br></br>
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-first-level-heading-mobile" : "impressum-first-level-heading"}>
          8. Lesbarkeit
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
        Um bei den auf unseren Internetseiten dargestellten Texten die Lesbarkeit zu verbessern, wird in der Regel nur das generische Maskulinum, also die männliche Form verwendet. Soweit nicht anders angegeben, sind alle Formulierungen aber geschlechtsneutral und umfassend zu verstehen.
        </div>
      </div>
    </div>
  );
};
  
export default Impressum;