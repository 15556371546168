import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaInstagram } from "react-icons/fa6";

const urlBasePath= process.env.REACT_APP_URL_BASE_PATH

const NavFooter = (props) => {
  const [pointerEvents, setPointerEvents] = useState("none")

  useEffect(() => {
    if (props.device === "mobile" || props.device === "tablet") {
      const element = document.querySelector("div.site-mobile")
      if (element.style.opacity !== "1") {
        setPointerEvents(false)
      } else {
        setPointerEvents(true)
      }
    } else if (props.device === "desktop") {
      const element = document.querySelector("div.site-desk")
      if (element.style.opacity !== "1") {
        setPointerEvents(false)
      } else {
        setPointerEvents(true)
      }
    }
  }, [props])

  if(!props.wideFooter){
    return (
      <>
        <div className="navfooter-mobile"  style={pointerEvents ? {pointerEvents: "auto", display:"block"} : {pointerEvents: "none", display:"block"}}> 
          <div className="nav-footer-link-external-mobile"> Autohaus Kauschinger bei
            <a className="nav-footer-mobile-link" href="https://home.mobile.de/KAUSCHINGERAUTOMOBILE#ses">
              <img src="/images/mobile.svg" className="navfooter-mobile-link-svg-desk" alt=""/>
            </a>
            <a className="nav-footer-mobile-link" href="https://www.instagram.com/autohaus_kauschinger/">
              <FaInstagram size={28}/>
            </a>
          </div>
        </div>
        <div className="navfooter-mobile" style={pointerEvents ? {pointerEvents: "auto", paddingTop:"10px", paddingBottom:"20px"} : {pointerEvents: "none", paddingTop:"10px", paddingBottom:"20px"} }> 
            <NavLink className="nav-footer-link-mobile" to="/cookies">Cookies</NavLink> 
            <NavLink className="nav-footer-link-mobile" to="/impressum">Impressum</NavLink> 
            <NavLink className="nav-footer-link-mobile" to="/datenschutz">Datenschutz</NavLink> 
        </div>
      </>
    )
  } else {
  return (
    <div className="navfooter-desk" style={pointerEvents ? {pointerEvents: "auto"} : {pointerEvents: "none"}}> 
    <div className="navfooter-left-ragged-desk"> 
      <div className="navfooter_stretch">
        <div className="nav-footer-link-external"> Autohaus Kauschinger bei
          <a className="nav-footer-mobile-link" href="https://home.mobile.de/KAUSCHINGERAUTOMOBILE#ses">
            <img src="/images/mobile.svg" className="navfooter-mobile-link-svg-desk" alt=""/>
          </a>
          <a className="nav-footer-mobile-link" href="https://www.instagram.com/autohaus_kauschinger/">
            <FaInstagram size={28}/>
          </a>
        </div>
      </div>
    </div>
    <div className="navfooter-right-ragged-desk">
      <div className="navfooter_stretch">
        <NavLink className="nav-footer-link-pages" to="/cookies">Cookies</NavLink> 
        <NavLink className="nav-footer-link-pages" to="/datenschutz">Datenschutz</NavLink> 
        <NavLink className="nav-footer-link-pages" to="/impressum">Impressum</NavLink> 
      </div>
    </div>
  </div>
  )
}
}

export default NavFooter;
