import React, { useEffect, useState } from "react";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

const Datenschutz = (props) => {

  const [device, setDevice] = useState()

  useEffect(() => {
    setDevice(props.device)
    CookieConsent.hide()
  }, [props]) 

  useEffect(() => {
    window.scrollTo(0, 0)
  })


  return (
    <div className="page-container" style={ device === "mobile" ? {"fontSize":"12pt"} : {} || device === "tablet" ? {"fontSize":"14pt"} : {} }>
      <div className="page-container-2">
        <div className="page-heading" style={ device === "mobile" ? {"fontSize":"20px"} : {} || device === "tablet" ? {"fontSize":"22pt"} : {} }>
          Datenschutz
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-first-level-heading-mobile" : "impressum-first-level-heading"}>
          Vorwort
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          Wir nehmen den Schutz Ihrer personenbezogenen Daten ernst und möchten Sie an dieser Stelle über den Datenschutz in unserem Unternehmen informieren.
          <br></br>
          <br></br>
          Uns sind im Rahmen unserer datenschutzrechtlichen Verantwortlichkeit durch das Inkrafttreten der EU-Datenschutz-Grundverordnung (Verordnung (EU) 2016/679; nachfolgend: "DS-GVO") zusätzliche Pflichten auferlegt worden, um den Schutz personenbezogener Daten der von einer Verarbeitung betroffenen Person (wir sprechen Sie als betroffene Person nachfolgend auch mit "Kunde", "Nutzer", "Sie", "Ihnen" oder "Betroffener" an) sicherzustellen.
          <br></br>
          <br></br>
          Soweit wir entweder alleine oder gemeinsam mit anderen über die Zwecke und Mittel der Datenverarbeitung entscheiden, umfasst dies vor allem die Pflicht, Sie transparent über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung zu informieren (vgl. Art. 13 und 14 DS-GVO). Mit dieser Erklärung (nachfolgend: "Datenschutzhinweise") informieren wir Sie darüber, in welcher Weise Ihre personenbezogenen Daten von uns verarbeitet werden.
          <br></br>
          <br></br>
          Unsere Datenschutzhinweise sind modular aufgebaut. Sie bestehen aus einem allgemeinen Teil für jegliche Verarbeitung personenbezogener Daten und Verarbeitungssituationen, die bei jedem Aufruf einer Webseite zum Tragen kommen (1. Allgemeines) und zwei besonderen Teilen, wesen Inhalte sich jeweils nur auf die dort angegebene Verarbeitungssituation mit Bezeichnung des jeweiligen Angebots oder Produkts bezieht, insbesondere den hier näher ausgestalteten Besuch von Webseiten (2. Besuch von Webseiten) und Social-Media Profilen (3. Besuch von Social-Media Profilen).
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-first-level-heading-mobile" : "impressum-first-level-heading"}>
          1. Allgemeines
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
          (1) Begriffsbestimmungen
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          Nach dem Vorbild des Art. 4 DS-GVO liegen dieser Datenschutzhinweise folgende Begriffsbestimmungen zugrunde:
          <ul className="impressum-bullet-list">
            <li className="impressum-bullet-point">"Personenbezogene Daten" (Art. 4 Nr. 1 DS-GVO) sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person ("Betroffener") beziehen. Identifizierbar ist eine Person, wenn sie direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, einer Kennnummer, einer Onlinekennung, Standortdaten oder mithilfe von Informationen zu ihren physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identitätsmerkmalen identifiziert werden kann. Die Identifizierbarkeit kann auch mittels einer Verknüpfung von derartigen Informationen oder anderem Zusatzwissen gegeben sein. Auf das Zustandekommen, die Form oder die Verkörperung der Informationen kommt es nicht an (auch Fotos, Video- oder Tonaufnahmen können personenbezogene Daten enthalten).</li>
            <li className="impressum-bullet-point">"Verarbeiten" (Art. 4 Nr. 2 DS-GVO) ist jeder Vorgang, bei dem mit personenbezogenen Daten umgegangen wird, gleich ob mit oder ohne Hilfe automatisierter (d.h. technikgestützter) Verfahren. Dies umfasst insbesondere das Erheben (d.h. die Beschaffung), das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, die Verbreitung oder sonstige Bereitstellung, den Abgleich, die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung von personenbezogenen Daten sowie die Änderung einer Ziel- oder Zweckbestimmung, die einer Datenverarbeitung ursprünglich zugrunde gelegt wurde.</li>
            <li className="impressum-bullet-point">"Verantwortlicher" (Art. 4 Nr. 7 DS-GVO) ist die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.</li>
            <li className="impressum-bullet-point">"Dritter" (Art. 4 Nr. 10 DS-GVO) ist jede natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer dem Betroffenen, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten; dazu gehören auch andere konzernangehörige juristische Personen.</li>
            <li className="impressum-bullet-point">"Auftragsverarbeiter" (Art. 4 Nr. 8 DS-GVO) ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen, insbesondere gemäß dessen Weisungen, verarbeitet (z.B. IT- Dienstleister). Im datenschutzrechtlichen Sinne ist ein Auftragsverarbeiter insbesondere kein Dritter.</li>
            <li className="impressum-bullet-point">"Einwilligung" (Art. 4 Nr. 11 DS-GVO) der betroffenen Person bezeichnet jede freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.</li>
          </ul>
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
          (2) Name und Anschrift des für die Verarbeitung Verantwortlichen
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>

          Die für die Verarbeitung Ihrer personenbezogenen Daten verantwortliche Stelle im Sinne des Art. 4 Nr. 7 DS-GVO ist: <br></br>
          <br></br>
          Christoph Kauschinger<br></br>
          Hauptstraße 18, 94469 Deggendorf, Deutschland <br></br>
          Telefon: <a href="tel:+4915165874553">+49 (0) 151 65874553</a><br></br>
          Email: <a style={{wordBreak:"break-all"}} href="mailto:info@autohaus-kauschinger.de?subject=Anfrage an das Autohaus Kauschinger">info@autohaus-kauschinger.de </a>  <br></br>
          (nachfolgend: "das Unternehmen", "wir" oder "uns") 
          <br></br>
          <br></br>
          Weitere Angaben zu unserem Unternehmen entnehmen Sie bitte den Impressumsangaben: <a style={{wordBreak:"break-all"}} href="https://www.autohaus-kauschinger.de/impressum">www.autohaus-kauschinger.de/impressum</a>.
        </div>
       <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
          (3) Rechtsgrundlagen der Datenverarbeitung
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
        Von Gesetzes wegen ist im Grundsatz jede Verarbeitung personenbezogener Daten verboten und nur dann erlaubt, wenn die Datenverarbeitung unter einen der folgenden Rechtfertigungstatbestände fällt:
        <ul className="impressum-bullet-list">
            <li className="impressum-bullet-point">Art. 6 Abs. 1 S. 1 lit. a DS-GVO ("Einwilligung"): Wenn der Betroffene freiwillig, in informierter Weise und unmissverständlich durch eine Erklärung oder eine sonstige eindeutige bestätigende Handlung zu verstehen gegeben hat, dass er mit der Verarbeitung der ihn betreffenden personenbezogenen Daten für einen oder mehrere bestimmte Zwecke einverstanden ist;</li>
            <li className="impressum-bullet-point">Art. 6 Abs. 1 S. 1 lit. b DS-GVO: Wenn die Verarbeitung zur Erfüllung eines Vertrags, dessen Vertragspartei der Betroffene ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist, die auf die Anfrage des Betroffenen erfolgen;</li>
            <li className="impressum-bullet-point">Art. 6 Abs. 1 S. 1 lit. c DS-GVO: Wenn die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der der Verantwortliche unterliegt (z. B. eine gesetzliche Aufbewahrungspflicht);</li>
            <li className="impressum-bullet-point">Art. 6 Abs. 1 S. 1 lit. d DS-GVO: Wenn die Verarbeitung erforderlich ist, um lebenswichtige Interessen des Betroffenen oder einer anderen natürlichen Person zu schützen;</li>
            <li className="impressum-bullet-point">Art. 6 Abs. 1 S. 1 lit. e DS-GVO: Wenn die Verarbeitung für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde oder</li>
            <li className="impressum-bullet-point">Art. 6 Abs. 1 S. 1 lit. f DS-GVO ("Berechtigte Interessen"): Wenn die Verarbeitung zur Wahrung berechtigter (insbesondere rechtlicher oder wirtschaftlicher) Interessen des Verantwortlichen oder eines Dritten erforderlich ist, sofern nicht die gegenläufigen Interessen oder Rechte des Betroffenen überwiegen (insbesondere dann, wenn es sich dabei um einen Minderjährigen handelt).</li>
          </ul>
          Die Speicherung von Informationen in der Endeinrichtung des Endnutzers oder der Zugriff auf Informationen, die bereits in der Endeinrichtung gespeichert sind, sind nur zulässig, wenn sie von einem der folgenden Rechtfertigungstatbeständen gedeckt sind:
          <ul className="impressum-bullet-list">
            <li className="impressum-bullet-point">§ 25 Abs. 1 TTDSG: Wenn der Endnutzer auf der Grundlage von klaren und umfassenden Informationen eingewilligt hat. Die Einwilligung hat gem. Art. 6 Abs. 1 S. 1 lit. a DS-GVO zu erfolgen;</li>
            <li className="impressum-bullet-point">§ 25 Abs. 2 Nr. 1 TTDSG: Wenn der alleinige Zweck die Durchführung der Übertragung einer Nachricht über ein öffentliches Telekommunikationsnetz ist oder</li>
            <li className="impressum-bullet-point">§ 25 Abs. 2 Nr. 2 TTDSG: Wenn die Speicherung oder der Zugriff unbedingt erforderlich ist, damit der Anbieter eines Telemediendienstes einen vom Nutzer ausdrücklich gewünschten Telemediendienst zur Verfügung stellen kann.</li>
          </ul>
          Für die von uns vorgenommenen Verarbeitungsvorgänge geben wir im Folgenden jeweils die anwendbare Rechtsgrundlage an. Eine Verarbeitung kann auch auf mehreren Rechtsgrundlagen beruhen.
       </div>
       <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
          (4) Datenlöschung und Speicherdauer
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          Für die von uns vorgenommenen Verarbeitungsvorgänge geben wir im Folgenden jeweils an, wie lange die Daten bei uns gespeichert und wann sie gelöscht oder gesperrt werden. Soweit nachfolgend keine ausdrückliche Speicherdauer angegeben wird, werden Ihre personenbezogenen Daten gelöscht oder gesperrt, sobald der Zweck oder die Rechtsgrundlage für die Speicherung entfällt. Eine Speicherung Ihrer Daten erfolgt grundsätzlich nur auf unseren Servern in Deutschland, vorbehaltlich einer ggf. erfolgenden Weitergabe nach den Regelungen in A.(7) und A.(8). Als Hostinganbieter nutzen wir DomainFactory: <a style={{wordBreak:"break-all"}} href="https://www.df.eu/de/ ">www.df.eu/de/</a>.
          <br></br>
          <br></br>
          Eine Speicherung kann jedoch über die angegebene Zeit hinaus im Falle einer (drohenden) Rechtsstreitigkeit mit Ihnen oder eines sonstigen rechtlichen Verfahrens erfolgen oder wenn die Speicherung durch gesetzliche Vorschriften, denen wir als Verantwortlicher unterliegen (zB § 257 HGB, § 147 AO), vorgesehen ist. Wenn die durch die gesetzlichen Vorschriften vorgeschriebene Speicherfrist abläuft, erfolgt eine Sperrung oder Löschung der personenbezogenen Daten, es sei denn, dass eine weitere Speicherung durch uns erforderlich ist und dafür eine Rechtsgrundlage besteht.
        </div>

        <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
          (5) Datensicherheit
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          Wir bedienen uns geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen (z.B. TLS-Verschlüsselung für unsere Webseite) unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Natur, des Umfangs, des Kontextes und des Zwecks der Verarbeitung sowie der bestehenden Risiken einer Datenpanne (inklusive von deren Wahrscheinlichkeit und Auswirkungen) für den Betroffenen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
        <br></br>
          Nähere Informationen hierzu erteilen wir Ihnen auf Anfrage gerne. Wenden Sie sich hierzu bitte an unseren Datenschutzbeauftragten (siehe unter A.(3)).
        </div>

        <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
          (6) Datensicherheit
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          Wie bei jedem größeren Unternehmen, setzen auch wir zur Abwicklung unseres Geschäftsverkehrs externe in- und ausländische Dienstleister ein (z.B. für die Bereiche IT, Logistik, Telekommunikation, Vertrieb und Marketing). Diese werden nur nach unserer Weisung tätig und wurden iSv Art. 28 DS-GVO vertraglich dazu verpflichtet, die datenschutzrechtlichen Bestimmungen einzuhalten.
          <br></br>
          Sofern personenbezogene Daten von Ihnen durch uns an unsere Tochtergesellschaften weitergegeben werden oder von unseren Tochtergesellschaften an uns weitergegeben werden (z.B. zu werblichen Zwecken), geschieht dies aufgrund von bestehenden Auftragsverarbeitungsverhältnissen.
        </div>

        <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
          (7) Datensicherheit
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          Im Rahmen unserer Geschäftsbeziehungen können Ihre personenbezogenen Daten an Drittgesellschaften weitergegeben oder offengelegt werden. Diese können sich auch außerhalb des Europäischen Wirtschaftsraums (EWR), also in Drittländern, befinden. Eine derartige Verarbeitung erfolgt ausschließlich zur Erfüllung der vertraglichen und geschäftlichen Verpflichtungen und zur Pflege Ihrer Geschäftsbeziehung zu uns (Rechtsgrundlage ist Art. 6 Abs. 1 lit b oder lit. f jeweils iVm Art. 44 ff. DS-GVO). Über die jeweiligen Einzelheiten der Weitergabe unterrichten wir Sie nachfolgend an den dafür relevanten Stellen. 
          <br></br>
          Einigen Drittländern bescheinigt die Europäische Kommission durch sog. Angemessenheitsbeschlüsse einen Datenschutz, der dem EWR-Standard vergleichbar ist (eine Liste dieser Länder sowie eine Kopie der Angemessenheitsbeschlüsse erhalten Sie hier: <a style={{wordBreak:"break-all"}} href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en</a>). In anderen Drittländern, in die ggf. personenbezogene Daten übertragen werden, herrscht aber unter Umständen wegen fehlender gesetzlicher Bestimmungen kein durchgängig hohes Datenschutzniveau. Soweit dies der Fall ist, achten wir darauf, dass der Datenschutz ausreichend gewährleistet ist. Möglich ist dies über bindende Unternehmensvorschriften, Standard-Vertragsklauseln der Europäischen Kommission zum Schutz personenbezogener Daten gem. Art. 46 Abs. 1, 2 lit. c DS-GVO (die Standard-Vertragsklauseln von 2021 sind verfügbar unter <a style={{wordBreak:"break-all"}} href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32021D0915&locale-en">https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32021D0915&locale-en</a>, Zertifikate oder anerkannte Verhaltenskodizes. Bitte wenden Sie sich an unseren Datenschutzbeauftragten (siehe unter A.(3)), wenn Sie hierzu nähere Informationen erhalten möchten.
        </div>

        <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
          (8) Keine automatisiere Entscheidungsfindung (einschließlich Profiling)
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          Wir haben nicht die Absicht, von Ihnen erhobene personenbezogene Daten für ein Verfahren zur automatisierten Entscheidungsfindung (einschließlich Profiling) zu verwenden.        </div>
          <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
          (9) Keine Verpflichtung zur Bereitstellung personenbezogener Daten
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          Wir machen den Abschluss von Verträgen mit uns nicht davon abhängig, dass Sie uns zuvor personenbezogene Daten bereitstellen. Für Sie als Kunde besteht grundsätzlich auch keine gesetzliche oder vertragliche Verpflichtung, uns Ihre personenbezogenen Daten zur Verfügung zu stellen; es kann jedoch sein, dass wir bestimmte Angebote nur eingeschränkt oder gar nicht erbringen können, wenn Sie die dafür erforderlichen Daten nicht bereitstellen. Sofern dies im Rahmen der nachfolgend vorgestellten, von uns angebotenen Produkte ausnahmsweise der Fall sein sollte, werden Sie gesondert darauf hingewiesen.
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
          (10) Gesetzliche Verpflichtung zur Übermittlung bestimmter Daten
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
         Wir können unter Umständen einer besonderen gesetzlichen oder rechtlichen Verpflichtung unterliegen, die rechtmäßig verarbeiteten personenbezogenen Daten für Dritte, insbesondere öffentlichen Stellen, bereitzustellen (Art. 6 Abs. 1 S. 1 lit. c DS-GVO).
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
          (11) Ihre Rechte
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          Ihre Rechte als Betroffener bezüglich Ihrer verarbeiteten personenbezogenen Daten können Sie uns gegenüber unter den eingangs unter A.(2) angegebenen Kontaktdaten jederzeit geltend machen. Sie haben als Betroffener das Recht:
          <ul className="impressum-bullet-list">
            <li className="impressum-bullet-point">gemäß Art. 15 DS-GVO Auskunft über Ihre von uns verarbeiteten Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;</li>
            <li className="impressum-bullet-point">gemäß Art. 16 DS-GVO unverzüglich die Berichtigung unrichtiger oder die Vervollständigung Ihrer bei uns gespeicherten Daten zu verlangen;</li>
            <li className="impressum-bullet-point">gemäß Art. 17 DS-GVO die Löschung Ihrer bei uns gespeicherten Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</li>
            <li className="impressum-bullet-point">gemäß Art. 18 DS-GVO die Einschränkung der Verarbeitung Ihrer Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird oder die Verarbeitung unrechtmäßig ist;</li>
            <li className="impressum-bullet-point">gemäß Art. 20 DS-GVO Ihre Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen ("Datenübertragbarkeit");</li>
            <li className="impressum-bullet-point">gemäß Art. 21 DS-GVO Widerspruch gegen die Verarbeitung einzulegen, sofern die Verarbeitung aufgrund von Art. 6 Abs. 1 S. 1 lit. e oder lit. f DS-GVO erfolgt. Dies ist insbesondere der Fall, wenn die Verarbeitung nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist. Sofern es sich nicht um einen Widerspruch gegen Direktwerbung handelt, bitten wir bei Ausübung eines solchen Widerspruchs um die Darlegung der Gründe, weshalb wir Ihre Daten nicht wie von uns durchgeführt verarbeiten sollen. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen;</li>
            <li className="impressum-bullet-point">gemäß Art. 7 Abs. 3 DS-GVO Ihre einmal (auch vor der Geltung der DS- GVO, dh vor dem 25.5.2018) erteilte Einwilligung – also Ihr freiwilliger, in informierter Weise und unmissverständlich durch eine Erklärung oder eine sonstige eindeutige bestätigende Handlung verständlich gemachter Willen, dass Sie mit der Verarbeitung der betreffenden personenbezogenen Daten für einen oder mehrere bestimmte Zwecke einverstanden sind – jederzeit uns gegenüber zu widerrufen, falls Sie eine solche erteilt haben. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und</li>
            <li className="impressum-bullet-point">gemäß Art. 77 DS-GVO sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten in unserem Unternehmen zu beschweren.</li>
          </ul>
        </div>

        <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
          (12) Änderungen der Datenschutzhinweise
        </div>
        <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
          Im Rahmen der Fortentwicklung des Datenschutzrechts sowie technologischer oder organisatorischer Veränderungen werden unsere Datenschutzhinweise regelmäßig auf Anpassungs- oder Ergänzungsbedarf hin überprüft. Über Änderungen werden Sie insbesondere auf unserer deutschen Webseite unter <a style={{wordBreak:"break-all"}} href="https://www.autohaus-kauschinger.de/impressum">https://www.autohaus-kauschinger.de/impressum</a> unterrichtet. Diese Datenschutzhinweise haben den Stand von Juli 2024.
        </div>


        <div className={ device === "mobile" || device === "tablet" ? "impressum-first-level-heading-mobile" : "impressum-first-level-heading"}>
        2. Besuch von Webseiten
      </div>

      <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
        (1) Erläuterung der Funktion
      </div>
      <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
        Informationen zu unseren Unternehmen und den von uns angebotenen Leistungen erhalten Sie insbesondere unter <a style={{wordBreak:"break-all"}} href="https://www.autohaus-kauschinger.de/">www.autohaus-kauschinger.de/</a>  samt den dazugehörigen Unterseiten (nachfolgend gemeinsam: "Webseiten"). Bei einem Besuch unserer Webseiten können personenbezogene Daten von Ihnen verarbeitet werden.
      </div>

      <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
        (2) Verarbeitete personenbezogene Daten
      </div>
      <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
        Bei der informatorischen Nutzung der Webseiten werden die folgenden Kategorien personenbezogener Daten von uns erhoben, gespeichert und weiterverarbeitet:
        <br></br>
        <br></br>
        "Protokolldaten": Wenn Sie unsere Webseiten besuchen, wird auf unserem Webserver temporär und anonymisiert ein sogenannter Protokolldatensatz (sog. Server-Logfiles) gespeichert. Dieser besteht aus:
        <ul className="impressum-bullet-list">
            <li className="impressum-bullet-point">der Seite, von der aus die Seite angefordert wurde (sog. Referrer-URL)</li>
            <li className="impressum-bullet-point">dem Name und URL der angeforderten Seite</li>
            <li className="impressum-bullet-point">dem Datum und der Uhrzeit des Aufrufs</li>
            <li className="impressum-bullet-point">der Beschreibung des Typs, Sprache und Version des verwendeten Webbrowsers</li>
            <li className="impressum-bullet-point">der IP-Adresse des anfragenden Rechners, die so verkürzt wird, dass ein Personenbezug nicht mehr herstellbar ist</li>
            <li className="impressum-bullet-point">der übertragenen Datenmenge</li>
            <li className="impressum-bullet-point">dem Betriebssystem</li>
            <li className="impressum-bullet-point">der Meldung, ob der Aufruf erfolgreich war (Zugriffsstatus/Http- Statuscode)</li>
            <li className="impressum-bullet-point">der GMT-Zeitzonendifferenz</li>
          </ul>
      </div>

      <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
        (3) Zwecke und Rechtsgrundlage der Datenverabeitung
      </div>
      <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
        Wir verarbeiten die vorstehend näher bezeichneten personenbezogenen Daten in Einklang mit den Vorschriften der DS-GVO, den weiteren einschlägigen Datenschutzvorschriften und nur im erforderlichen Umfang. Soweit die Verarbeitung der personenbezogenen Daten auf Art. 6 Abs. 1 S. 1 lit. f DS-GVO beruht, stellen die genannten Zwecke zugleich unsere berechtigten Interessen dar.
        <br></br>
        <br></br>
        Die Verarbeitung der Protokolldaten dient statistischen Zwecken und der Verbesserung der Qualität unserer Webseite, insbesondere der Stabilität und der Sicherheit der Verbindung (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. a oder lit. f DS- GVO).
        <br></br>
        <br></br>
        Sofern für die Verarbeitung der Daten die Speicherung von Informationen in Ihrer Endeinrichtung oder der Zugriff auf Informationen, die bereits in der Endeinrichtung gespeichert sind, erforderlich ist, ist § 25 Abs. 1, 2 TTDSG hierfür die Rechtsgrundlage.
      </div>

      <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
        (4) Dauer der Datenverarbeitung
      </div>
      <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
      Ihre Daten werden nur so lange verarbeitet, wie dies für die Erreichung der oben genannten Verarbeitungszwecke erforderlich ist; hierfür gelten die im Rahmen der Verarbeitungszwecke angegebenen Rechtsgrundlagen entsprechend. Hinsichtlich der Nutzung und der Speicherdauer von Cookies beachten Sie bitte Punkt A.(5) sowie die Cookie-Richtlinie <a style={{wordBreak:"break-all"}} href="https://www.autohaus-kauschinger.de/cookies">www.autohaus-kauschinger.de/cookies</a>. Von uns eingesetzte Dritte werden Ihre Daten auf deren System so lange speichern, wie es im Zusammenhang mit der Erbringung der Leistungen für uns entsprechend dem jeweiligen Auftrag erforderlich ist. Näheres zur Speicherdauer finden Sie im Übrigen unter A.(5) und der Cookie- Richtlinie <a style={{wordBreak:"break-all"}} href="https://www.autohaus-kauschinger.de/cookies">www.autohaus-kauschinger.de/cookies</a>.
      </div>

      <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
        (5) Übermittlung personenbezogener Daten an Dritte; Rechtfertigungsgrundlage
      </div>
      <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
      Folgende Kategorien von Empfängern, bei denen es sich im Regelfall um Auftragsverarbeiter handelt (siehe dazu A.(7)), erhalten ggf. Zugriff auf Ihre personenbezogenen Daten:
        <ul className="impressum-bullet-list">
          <li className="impressum-bullet-point">Dienstleister für den Betrieb unserer Webseite und die Verarbeitung der durch die Systeme gespeicherten oder übermittelten Daten (z.B. für Rechenzentrumsleistungen, Zahlungsabwicklungen, IT-Sicherheit). Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1 lit. b oder lit. f DS-GVO, soweit es sich nicht um Auftragsverarbeiter handelt;</li>
          <li className="impressum-bullet-point">Staatliche Stellen/Behörden, soweit dies zur Erfüllung einer gesetzlichen Verpflichtung erforderlich ist. Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1 lit. c DS-GVO;</li>
          <li className="impressum-bullet-point">Zur Durchführung unseres Geschäftsbetriebs eingesetzte Personen (z.B. Auditoren, Banken, Versicherungen, Rechtsberater, Aufsichtsbehörden, Beteiligte bei Unternehmenskäufen oder der Gründung von Gemeinschaftsunternehmen). Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1 lit. b oder lit. f DS-GVO.</li>
        </ul>
        Zu den Gewährleistungen eines angemessenen Datenschutzniveaus bei einer Weitergabe der Daten in Drittländer siehe A.(8).
        <br></br>
        <br></br>
        Darüber hinaus geben wir Ihre personenbezogenen Daten nur an Dritte weiter, wenn Sie nach Art. 6 Abs. 1 S. 1 lit. a DS-GVO eine ausdrückliche Einwilligung dazu erteilt haben.
      </div>

      <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
        (6) Einsatz von Cookies, Plugins und sonstige Dienste auf unserer Webseite
      </div>
      <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
        a) Cookie
        <div style={{marginBottom:"8px"}}></div>
        Auf unseren Webseiten nutzen wir Cookies. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser durch eine charakteristische Zeichenfolge zugeordnet und gespeichert werden und durch welche der Stelle, die das Cookie setzt, bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen und daher keine Schäden anrichten. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver, also für Sie angenehmer zu machen.
        <br></br>
        Cookies können Daten enthalten, die eine Wiedererkennung des genutzten Geräts möglich machen. Teilweise enthalten Cookies aber auch lediglich Informationen zu bestimmten Einstellungen, die nicht personenbeziehbar sind. Cookies können einen Nutzer aber nicht direkt identifizieren.
        <br></br>
        Man unterscheidet zwischen Session-Cookies, die wieder gelöscht werden, sobald Sie ihren Browser schließen und permanenten Cookies, die über die einzelne Sitzung hinaus gespeichert werden. Hinsichtlich ihrer Funktion unterscheidet man bei Cookies wiederum zwischen:
        <ul className="impressum-bullet-list">
          <li className="impressum-bullet-point">Technical Cookies: Diese sind zwingend erforderlich, um sich auf der Webseite zu bewegen, grundlegende Funktionen zu nutzen und die Sicherheit der Webseite zu gewährleisten; sie sammeln weder Informationen über Sie zu Marketingzwecken noch speichern sie, welche Webseiten Sie besucht haben;</li>
          <li className="impressum-bullet-point">Performance Cookies: Diese sammeln Informationen darüber, wie Sie unsere Webseite nutzen, welche Seiten Sie besuchen und zB ob Fehler bei der Webseitennutzung auftreten; sie sammeln keine Informationen, die Sie identifizieren könnten – alle gesammelten Informationen sind anonym und werden nur verwendet, um unsere Webseite zu verbessern und herauszufinden, was unsere Nutzer interessiert;</li>
          <li className="impressum-bullet-point">Advertising Cookies, Targeting Cookies: Diese dienen dazu, dem Webseitennutzer bedarfsgerechte Werbung auf der Webseite oder Angebote von Dritten anzubieten und die Effektivität dieser Angebote zu messen; Advertising und Targeting Cookies werden maximal 13 Monate lang gespeichert;</li>
          <li className="impressum-bullet-point">Sharing Cookies: Diese dienen dazu, die Interaktivität unserer Webseite mit anderen Diensten (zB sozialen Netzwerken) zu verbessern; Sharing Cookies werden maximal 13 Monate lang gespeichert.</li>
        </ul>
        Rechtsgrundlage für Cookies, die unbedingt erforderlich sind, um Ihnen den ausdrücklich gewünschten Dienst zur Verfügung zustellen, ist § 25 Abs. 2 Nr. 2 TTDSG. Jeder Einsatz von Cookies, der hierfür nicht zwingend technisch erforderlich ist, stellt eine Datenverarbeitung dar, die nur mit einer ausdrücklichen und aktiven Einwilligung Ihrerseits gem. § 25 Abs. 1 TTDSG iVm Art. 6 Abs. 1 S. 1 lit. a DS-GVO erlaubt ist. Dies gilt insbesondere für die Verwendung von Performance, Advertising, Targeting oder Sharing Cookies. Darüber hinaus geben wir Ihre durch Cookies verarbeiteten personenbezogenen Daten nur an Dritte weiter, wenn Sie nach Art. 6 Abs. 1 S. 1 lit. a DS-GVO eine ausdrückliche Einwilligung dazu erteilt haben.
        <br></br>
        <br></br>
        b) Cookie-Richtlinie
        <div style={{marginBottom:"8px"}}></div>
        Weitere Informationen darüber, welche Cookies wir verwenden finden Sie in unserer Cookie-Richtlinie: <a style={{wordBreak:"break-all"}} href="https://www.autohaus-kauschinger.de/cookies">https://www.autohaus-kauschinger.de/cookies</a>.
        <br></br>
        <br></br>
        c) Social Media Plugins
        <div style={{marginBottom:"8px"}}></div>
        Auf unseren Webseiten setzen wir keine Social-Media-Plugins ein. Sofern unsere Webseiten Symbole von Social-Media-Anbietern enthalten (z.B. mobile.de und Instagram), nutzen wir diese lediglich zur passiven Verlinkung auf die Seiten der jeweiligen Anbieter.
      </div>


      <div className={ device === "mobile" || device === "tablet" ? "impressum-first-level-heading-mobile" : "impressum-first-level-heading"}>
        3. Besuch von Social-Media Profilen
      </div>
      <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
        (1) Allgemeines
      </div>
      <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
        Wir unterhalten in verschiedenen sozialen Netzwerken öffentlich zugängliche Profile. Ihr Besuch dieser Profile setzt eine Vielzahl von Datenverarbeitungsvorgängen in Gang. Nachfolgend geben wir Ihnen einen Überblick darüber, welche Ihrer personenbezogenen Daten von uns erhoben, genutzt und gespeichert werden, wenn Sie unsere Profile besuchen. Personenbezogene Daten sind dabei solche Angaben, die sich Ihnen als bestimmte Person zuordnen lassen (z.B. Name, Alter, Anschrift, Fotos, E-Mail-Adressen, IP-Adressen). Ferner informieren wir Sie darüber, welche Rechte Sie im Hinblick auf die Verarbeitung Ihrer personenbezogenen Daten uns gegenüber haben. Sie sind nicht verpflichtet, uns Ihre personenbezogenen Daten zu überlassen. Allerdings kann dies für einzelne Funktionalitäten unserer Profile in sozialen Netzwerken erforderlich sein. Diese Funktionalitäten stehen Ihnen nicht oder nur eingeschränkt zur Verfügung, wenn Sie uns Ihre personenbezogenen Daten nicht überlassen.
        <br></br>
        Bei Ihrem Besuch unserer Profile werden Ihre personenbezogenen Daten nicht nur durch uns, sondern auch durch die Betreiber des jeweiligen sozialen Netzwerks erhoben, genutzt und gespeichert. Dies geschieht auch dann, wenn Sie selbst kein Profil in dem jeweiligen sozialen Netzwerk haben. Die einzelnen Datenverarbeitungsvorgänge und ihr Umfang unterscheiden sich je nach Betreiber des jeweiligen sozialen Netzwerks und sie sind nicht zwingend für uns nachvollziehbar. Einzelheiten über die Erhebung und Speicherung Ihrer persönlichen Daten sowie über Art, Umfang und Zweck ihrer Verwendung durch den Betreiber des jeweiligen sozialen Netzwerks entnehmen Sie bitte den Datenschutzerklärungen des jeweiligen Betreibers:
        <ul className="impressum-bullet-list">
          <li className="impressum-bullet-point">die Datenschutzerklärung für das soziale Netzwerk mobile.de, das von mobile.de GmbH betrieben wird, können Sie
          unter <a style={{wordBreak:"break-all"}} href="https://www.mobile.de/service/privacyPolicy">https://www.mobile.de/service/privacyPolicy</a> einsehen;</li>
          <li className="impressum-bullet-point">die Datenschutzerklärung für das soziale Netzwerk Instagram, das von Meta Platforms Ireland Limited betrieben wird, können Sie
          unter <a style={{wordBreak:"break-all"}} href="https://help.instagram.com/155833707900388">https://help.instagram.com/155833707900388</a> einsehen</li>
        </ul>
      </div>
      <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
        (2) Informationen über die Erhebung personenbezogener Daten
      </div>
      <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
        Sofern Sie unsere Profile in sozialen Netzwerken nutzen, um mit uns Kontakt aufzunehmen (bspw. durch die Erstellung eigener Beiträge, die Reaktion auf einen unserer Beiträge oder durch private Nachrichten an uns), werden die uns von Ihnen mitgeteilten Daten von uns ausschließlich zu dem Zweck verarbeitet, mit Ihnen in Kontakt treten zu können. Rechtsgrundlage für die Datenerhebung ist damit Art. 6 Abs. 1 lit. a) und b) DS-GVO.12. Wir löschen gespeicherte Daten sobald deren Speicherung nicht mehr erforderlich ist oder Sie uns zu deren Löschung auffordern; im Falle gesetzlicher Aufbewahrungspflichten beschränken wir die Verarbeitung der gespeicherten Daten entsprechend.
        <br></br>
        <br></br>
        Die Verantwortlichkeiten und Kontaktdaten entnehmen Sie bitte A.(2) und A.(3).
      </div>

      <div className={ device === "mobile" || device === "tablet" ? "impressum-second-level-heading-mobile" : "impressum-second-level-heading"}>
        (3) Ihre Rechte als von der Datenverarbeitung betroffene Person
      </div>
      <div className={ device === "mobile" || device === "tablet" ? "impressum-content-block-mobile" : "impressum-content-block"}>
      Als von der Datenverarbeitung betroffene Person haben Sie folgende Rechte:
      <ul className="impressum-bullet-list">
        <li className="impressum-bullet-point">Sie haben das Recht, im Umfang von Art. 15 DS-GVO Auskunft von uns
        über die Verarbeitung Ihrer personenbezogenen Daten zu erhalten;</li>
        <li className="impressum-bullet-point">Sie haben das Recht, im Umfang von Art. 16 DS-GVO unverzüglich Berichtigung der Sie betreffenden unrichtigen personenbezogenen Daten und/oder Vervollständigung unvollständiger personenbezogener Daten von uns zu verlangen;</li>
        <li className="impressum-bullet-point">Sie haben das Recht, im Umfang von Art. 17 DS-GVO die unverzügliche Löschung der Sie betreffenden personenbezogenen Daten zu verlangen;</li>
        <li className="impressum-bullet-point">Sie haben das Recht, im Umfang von Art. 18 DS-GVO die Einschränkung der Sie betreffenden Datenverarbeitung zu verlangen;</li>
        <li className="impressum-bullet-point">Sie haben das Recht, im Umfang von Art. 20 DS-GVO die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten und maschinenlesbaren Format zu erhalten und diese Daten einem anderen Verantwortlichen zu übermitteln;</li>
        <li className="impressum-bullet-point">Sie haben das Recht, im Umfang von Art. 21 DS-GVO aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit der Verarbeitung der Sie betreffenden personenbezogenen Daten zu widersprechen, sofern die Verarbeitung auf einem überwiegenden Interesse beruht oder Ihre Daten zum Zwecke der Direktwerbung genutzt werden;</li>
        <li className="impressum-bullet-point">Sie haben das Recht, Ihre Einwilligung in die Datenverarbeitung jederzeit zu widerrufen, ohne dass dadurch die Rechtmäßigkeit der bis zum Widerruf aufgrund der Einwilligung erfolgten Datenverarbeitung berührt wird;</li>
        <li className="impressum-bullet-point">Sie haben das Recht, sich bei einer Aufsichtsbehörde über unsere Verarbeitung Ihrer Daten beschweren.</li>
      </ul>
      </div>
      </div>
    </div>
  );
};
  
export default Datenschutz;