import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
  
const My404Component = (props) => {
  const [device, setDevice] = useState(props.props)
  const navigate = useNavigate()

  useEffect(() => {
    setDevice(props.props)
    if (document.cookie === "") {
      navigate("/home");
      window.location.reload();
    } 
  }, [navigate, props])

  return (
    <div className="page-container" style={ device === "mobile" ? {"fontSize":"12pt"} : {} || device === "tablet" ? {"fontSize":"14pt"} : {} }>
      <div className="page-container-2">
        <div className="page-heading" style={ device === "mobile" ? {"fontSize":"20px"} : {} || device === "tablet" ? {"fontSize":"22pt"} : {} }>
        </div>
        Die aufgerufene Seite exisitert nicht. Bitte klicken sie auf folgenden Link um auf unsere Startseite zu gelangen: <Link to="/Home"> Home </Link>
      </div>
    </div>
  );
};
  
export default My404Component;